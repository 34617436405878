.side-nav {
  margin-bottom: 35px;
  display: none;
  &__toggle {
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    @include h6-base();
    border-bottom: solid 1px #e6e6e6;
    cursor: pointer;
    img {
      height: 30px;
      width: 30px;
      margin: 0 8px 0 0;
    }
    position: relative;
    .side-nav__close {
      position: absolute;
    }
    .side-nav__open, .side-nav__close {
      opacity: 0;
      transition: $transition;
    }
    &[data-state="open"] {
      .side-nav__close {
        opacity: 1;
      }
    }
    &[data-state="closed"] {
      .side-nav__open {
        opacity: 1;
      }
    }
  }
  &__menu--open {
    @extend .container;
    .side-nav__menu {
      display: block!important;
      border-top: 1px solid #e6e6e6;
      width: 100%;
      height: auto!important;
    }
  }
  &__menu {
    display: none;
    list-style: none;
    padding-left: 0 !important;
    margin: 0 !important;
    @include media-breakpoint-down(md) {
      position: static!important;
    }
    svg {
      height: 12px;
      width: 22px;
      fill: $body-text-color;
      margin-right: 3px;
      transition: $transition;
    }
    li {
      border-bottom: solid 1px #e6e6e6;
      margin-bottom: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    a {
      @include h6-base();
      display: flex;
      align-items: center;
      height: 68px;
      color: $menu-text;
    }
    a.active, a:hover {
      color: $secondary-color;
      svg {
        fill: $secondary-color;
      }
    }
  }
  &--game-day {
    .side-nav__menu {
      .side-nav__icon-background {
        width: 50px;
        height: 50px;
        background-color: $greyish;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 14px 0 0;
        transition: $transition;
      }
      a {
        color: #555555;
      }
      a.active, a:hover {
        color: $secondary-color;
        .side-nav__icon-background {
          background-color: $secondary-color;
        }
      }
      img {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .side-nav {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .side-nav {
    &__toggle {
      display: none;
    }
    &__menu {
      display: block!important;
      border-top: solid 1px #e6e6e6;
      width: 210px;
      // padding-top: 30px;
      margin-bottom: 0;
    }
    &__menu--open {
      display: none!important;
    }
  }
}

@include media-breakpoint-up(xl) {
  .side-nav {
    &__menu {
      width: 262.5px;
    }
  }
}
