.match-info {
  background-color: $off-white;
  width: 100%;
  margin-top: 4px;
  padding: 24px 32px;
  &__sponsor {
    display: flex;
    margin-bottom: 15px;
    > div:first-of-type {
      width: 49px;
      margin-right: 24px;
    }
    > div:last-of-type {
      width: 130px;
      position: relative;
      bottom: 6px;
    }
    &:first-of-type {
      img {
        width: 49px;
        height: 26px;
      }
    }
    &:last-of-type {
      img {
        width: 42px;
        height: 42px;
      }
    }
  }
  &__heading {
    font-weight: 600;
    line-height: 22px;
  }
  &__text {
    font-weight: 400;
    line-height: 22px;
  }
  &__buttons {
    text-align: center;
    .btn:first-of-type {
      margin-bottom: 10px;
    }
  }
}

@media(min-width: 422px) {
  .match-info {
    &__buttons {
      .btn:first-of-type {
        margin-bottom: 0px;
      }
      .btn {
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .match-info {
    padding: 32px 32px 36px 32px;
    &__sponsors {
      display: flex;
      align-items: center;
    }
    &__sponsor {
      flex: 0 0 50%;
    }
  }
}

@include media-breakpoint-up(md) {
  .match-info {
    display: flex;
    &__sponsors {
      display: flex;
      align-items: center;
      flex: 0 0 66.666%;
    }
    &__sponsor {
      flex: 0 0 50%;
      margin-bottom: 0;
    }
    &__buttons a:first-of-type {
      margin-bottom: 5px!important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .match-info {
    justify-content: space-between;
    padding-right: 29px;
    &__sponsors {
      flex: 0 0 auto;
    }
    &__sponsor {
      flex: 0 0 auto;
      &:first-of-type {
        margin-right: 60px;
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      a {
        margin-bottom: 0!important;
      }
    }
  }
}
