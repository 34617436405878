.not-found {
  min-height: 560px;
  height: calc(100vh - 317px);
  background-color: $primary-color;
  @include image-background();
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__image {
    margin-bottom: 42px;
  }
  &__heading {
    margin-bottom: 24px;
    line-height: 0.95;
  }
  &__sub-heading {
    color: #fff;
    margin-bottom: 32px;
  }
  &::before {
    @include background-overlay();
    background-image: linear-gradient(to bottom, rgba($primary, 0), rgba($primary, 0.38));
  }
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path d="M0 0h1v1H0V0z" fill="%23000000" /></svg>');
    background-repeat: repeat;
    background-size: 2px 2px;
    opacity: .2;
  }
  &__container {
    @extend .container;
    z-index: 1;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@include media-breakpoint-up(md) {
  .not-found {
    height: calc(100vh - 150px);
  }
}

@include media-breakpoint-up(lg) {
  .not-found {
    height: calc(100vh - 80px);
  }
}
