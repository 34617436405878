.advertising-space {
  height: 96px;
  background-color: $off-white;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: #4a4a4a;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.mrec {
  height: auto;
  display: block;
  background-color: transparent;

  .advertising-space__container {
    display: block;
    height: auto;
    max-height: 300px;
    max-width: 300px;
    background-color: transparent;
  }
}

@include media-breakpoint-up(md) {
  .advertising-space {
    margin-top: 2px;
    height: 272px;
    justify-content: center;
    &__container {
      width: 728px;
      height: 90px;
    }
  }
  .mrec {
    height: auto;

    .advertising-space__container {
      width: auto;
      height: auto;
    }
  }
}
