.fixtures {
  @include module-margins();
  &__heading {
    @include section-heading;
    display: flex;
    justify-content: space-between;
  }
  .game-card {
    background-color: $off-white;
    transition: background-color $transition-speed;
    &:not(:last-of-type) {
      border-bottom: 1px solid #fff;
    }
    &__teams {
      img {
        max-height: 64px;
        max-width: 64px;
      }
    }
    &__date {
      margin-bottom: 0;
    }
    &__score--score {
      font-size: 32px;
      font-weight: 700;
      margin-left: 23.5px;
      margin-right: 23.5px;
      opacity: 1;
      color: #b3b3b3;
    }
    &__score--v {
      margin-left: 28px;
      margin-right: 28px;
      font-weight: 900;
    }
    &--loading {
      .game-card {
        &__button-wrap {
          display: none;
        }
      }
    }
  }
  strong {
    font-weight: 700;
    color: $section-heading-color;
  }
}

.fixture-sync {
  margin-top: 60px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 3rem 0;
  display: flex;
  align-items: center;

  h4 {
    display: flex;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: auto;
  }

  button {
    display: flex !important;
    margin-left: 20px !important;
  }
}

@include media-breakpoint-up(sm) {
  .fixtures {
    &__container {
      @include make-container();
      @include make-container-max-widths()
    }
  }
}

@include media-breakpoint-up(lg) {
  .fixtures {
    .game-card {
      height: 272px;
      position: relative;
      &:not(:first-of-type) {
        border-top: none;
        border-left: 1px solid rgba(#fff, 0.9);
      }
      transition: all $card-transition;
      cursor: pointer;
      z-index: 1;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: $box-shadow-initial;
      border-bottom: none!important;
      .game-card__wrap {
        position: absolute;
        padding: 40px 15px 36px;
        background-color: $off-white;
        width: 310px;
        height: 272px;
        transition: all $card-transition;
      }
      .game-card__button-wrap {
        transition: opacity linear 0s;
        padding-top: 0px;
        opacity: 0;
        flex-direction: row;
      }
      .game-card__teams {
        margin-bottom: 30px;
      }
      &:not(.game-card--live) {
        &:hover {
          .game-card__wrap {
            padding-top: 28px;
            padding-bottom: 34px;
          }
        }
      }
      &:hover {
        z-index: 2;
        .game-card__wrap {
          width: 394px;
          height: 320px;
          box-shadow: $box-shadow-hover;
          border-left: 1px solid rgba(255, 255, 255, 0.1);
          border-right: 1px solid rgba(255, 255, 255, 0.1);
          margin: auto;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
        }
        //.game-card__teams {
        //  margin-bottom: 20px;
        //}
        .game-card__button-wrap {
          height: 82px;
          padding-top: 22px;
          opacity: 1;
          transition: opacity linear 0.2s 0.2s;
        }
      }
      &__score--score {
        @include media-breakpoint-down(lg) {
          margin-left: 10px;
          margin-right: 10px;
          letter-spacing: -0.5px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .fixtures {
    .game-card {
      .game-card__wrap {
        width: 380px;
        left: 0;
      }
      &:hover {
        .game-card__wrap {
          left: -7px;
        }
      }
    }
  }
}
