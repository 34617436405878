.results {
  @include module-padding();
  margin-bottom: 80px;
  .switch__wrap {
    margin-bottom: 40px;
  }
  .btn--pdf {
    margin-top: 42px;
  }
  .game-card {
    border-bottom: 1px solid #E0E0E0;
    border-top: 3px solid #CCCCCC;
    margin-bottom: -1px;
    cursor: default;
    &__score-extended {
      display: none;
    }
    .game-card__heading, .game-card__date, .game-card__time-location, .btn--outline {
      transition: $transition;
    }
  }
  &__button-wrap {
    display: none;
  }
}

.upcoming-tab {
  .game-card .btn {
    min-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.results-tab {
  .game-card__fixture-heading_right {
    position: relative;
    bottom: 5px;
    img {
      margin-left: 2px;
    }
  }
}

.results-tab, .upcoming-tab {
  .game-card__button-wrap {
    display: flex;
    margin-top: 40px;
    @include media-breakpoint-between(xs, md) {
      width: 100%;
    }
    .btn {
      flex: 1;
      background-color: transparent;
      border: 2px solid $body-text-color;
      color: $body-text-color!important;
      &:hover {
        border: 2px solid #000;
        color: #000!important;
      }
      &::before {
        background-image: url("/static/media/svg/play-icon.svg");
      }
    }
  }
  .game-card {
    padding-top: 25px;
    padding-bottom: 45px;
    &__teams {
      img {
        max-height: 64px;
        max-width: 64px;
        width: 64px;
      }
    }
    &__result-wrap {
      font-weight: 500;
      text-align: right;
      font-size: 12px;
      color: #555555;
      text-align: right;
      line-height: 20px;
    }
    &--live {
      border-top: 3px solid $tertiary-color!important;
      .game-card__teams img {
        max-height: 72px;
        max-width: 72px;
        width: 72px;
      }
    }
    &--loading {
      .game-card {
        &__heading {
          width: auto;
          height: auto;
          border-radius: 0;
          background-color: transparent;
          opacity: 1;
          margin-left: 0;
          margin-right: 0;
        }
        &__date-time {
          div {
            margin-left: 0;
            width: 180px;
            height: 10px;
            opacity: 1;
            background-color: #F8F8F8;
          }
          @include media-breakpoint-between(lg, lg) {
            div:last-of-type {
              display: none;
            }
          }
          @include media-breakpoint-up(xl) {
            div:first-of-type {
              width: 70px;
            }
            div:last-of-type {
              width: 242px;
            }
          }
        }
        &__score--score {
          margin-left: 30px;
          margin-right: 30px;
        }
      }
    }
  }
  .game-card__live {
    height: 24px;
    margin-bottom: 0;
    margin-top: 3px;
  }
  .game-card--live {
    border-top: 3px solid $tertiary-color;
  }
  .game-card--live, .game-card--complete {
    .game-card__score--score {
      margin-left: 8px;
      margin-right: 8px;
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      line-height: 20px;
    }
  }
  .game-card__fixture-heading {
    display: block;
    @include media-breakpoint-between(xs, md) {
      width: 100%;
    }
  }
  .game-card__time-location, .game-card__date, .game-card__heading {
    display: none;
  }
  .game-card__fixture-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .game-card__time, .game-card__location {
      padding-right: 22px;
      position: relative;
      font-weight: 500;
      text-align: right;
      font-size: 12px;
      color: #555555;
      text-align: right;
      line-height: 20px;
      &::after {
        content: "";
        position: absolute;
        height: 16px;
        width: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        right: -2px;
        top: 2px;
      }
    }
    &_left {
      text-align: left;
      p {
        font-size: 16px;
        color: #B3B3B3;
        line-height: 20px;
        font-weight: 700;
      }
    }
    .game-card__time {
      &::after {
        background-image: url("/static/media/svg/time.svg");
      }
    }
    .game-card__location {
      &::after {
        background-image: url("/static/media/svg/location.svg");
      }
    }
  }
}
.results-tab .row, .upcoming-tab .row {
  margin-bottom: 100px;
  h3 {
    margin-bottom: 35px;
  }
}

@include media-breakpoint-up(lg) {
  .results {
    .switch {
      width: 326px;
      &__wrap {
        align-items: center;
        justify-content: space-between;
        margin-bottom: 56px;
      }
    }
    .btn--pdf {
      margin-top: 0;
      display: flex!important;
    }
    &__button-wrap {
      display: flex;
      a:last-of-type {
        order: 0;
        margin-right: 6px;
      }
      a:first-of-type {
        order: 1;
      }
    }
  }
  .results-tab, .upcoming-tab {
    .game-card {
      padding-left: 0!important;
      padding-right: 0!important;
      padding-top: 30px;
      padding-bottom: 30px;
      cursor: default;
      border-top: 1px solid #CCCCCC;
      margin-bottom: -
      &__sub-heading {
        font-weight: 500;
        font-size: 12px;
        color: #555555;
        line-height: 20px;
        display: block;
      }
      &__wrap {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;
      }
      &__fixture-heading {
        display: none;
      }
      &__time-location, &__date, &__heading {
        display: block;
      }
      &__heading {
        width: 114px;
        text-align: left;
        flex: 0 0 114px;
        margin-bottom: 0;
      }
      &__teams {
        margin-bottom: 0;
        flex: 1 1 50px;
      }
      &:hover {
        background-color: #fff;
        .game-card__heading {
          color: $tertiary-color;
        }
        .game-card__date {
          color: #000;
        }
        .game-card__time-location {
          color: #000;
        }
        .btn--play + .btn {
          border: 2px solid #000;
          color: #000;
        }
      }
      &__heading {
        text-align: left !important;
        width: 250px;
        p {
          font-size: 1.2rem;
        }
      }
      &__button-wrap {
        flex-direction: row;
        justify-content: flex-end;
        min-width: 240px;
        margin-top: 0;
      }
      &__team-two div {
        color: $greyish;
      }
      &__date-time {
        text-align: left;
        flex: 1;
        .game-card__date {
          order: 0;
        }
        .game-card__time-location {
          order: 1;
        }
      }
      &__date {
        margin-bottom: 0;
      }
      > * {
        margin-bottom: 0;
      }
      &::before {
        display: none;
      }
      &__result {
        position: relative;
        top: auto;
        left: 10px;
        margin-right: 25px;
        margin-left: auto;
      }
      .btn {
        flex: 0 0;
        flex-basis: calc(50% - 1.5px);
      }
      .btn:first-of-type {
        margin-right: 3px;
      }
    }
  }
  .results-tab {
    .game-card {
      &__date-time {
        display: none;
      }
      &__teams {
        align-items: flex-start;
        display: none;
        &--resulted {
          div, div span  {
            font-weight: 700;
            font-size: 30px;
            opacity: 1;
            color: #B3B3B3;
            line-height: 20px;
          }
        }
        img {
          max-height: 34px;
          max-width: 34px;
          width: 34px;
        }
        &--resulted {
          display: block;
          > div {
            display: flex;
            align-items: center;
          }
        }
      }
      &--loading {
        .game-card__team-one, .game-card__team-two {
          width: auto;
          background-color: transparent;
        }
        .game-card__loading-logo {
          width: 37px;
          height: 37px;
          border-radius: 7px;
          background-color: #F5F5F5;
          margin-right: 19px;
        }
        .game-card__time-loading {
          height: 10px;
          border-radius: 7px;
          opacity: 0.19;
          width: 270px;
        }
        .game-card__teams + .game-card__date-time {
          display: none;
        }
      }
      &__team-one {
        margin-bottom: 15px;
      }
      &__score {
        margin-left: 17px;
        margin-right: 17px;
      }
      .game-card__result-image {
        display: block!important;
      }
      &__score-extended {
        display: flex;
        width: 80px;
        justify-content: space-evenly;
        margin-right: 20px;
        span {
          font-size: 10px!important;
          font-weight: bold!important;
          line-height: 1.1!important;
          color: $greyish!important;
          margin-right: 0!important;
          margin-left: 0!important;
          &.winner {
            color: $body-text-color!important;
          }
        }
        + div {
          display: flex;
          align-items: center;
        }
        .winner {
          color: $body-text-color;
          font-weight: bold;
        }
      }
      &__date-time {
        bottom: 3px!important;
        .game-card__date {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .upcoming-tab {
    .game-card {
      &__date {
        margin-right: 32px;
        padding: 0;
      }
      &__date-time {
        flex-direction: row;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .results-tab, .upcoming-tab {
    .game-card {
      display: flex;
      height: auto;
      &__team-one, &__team-two {
        display: flex;
        align-items: center;
      }
      &__date-time {
        margin-left: auto;
        margin-right: 10px;
        display: flex;
        position: relative;
        bottom: 18px;
        flex-basis: 50px;
      }
      &__teams {
        flex: 1 1 0px;
      }
      &__button-wrap {
        width: 259px;
      }
    }
  }
}
