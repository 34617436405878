.hero__tile {
  height: 100%;
  @supports (object-fit: cover) {
    height: auto;
  }
}

.hero__content .col-12:first-of-type {
  margin-bottom: 20px;
  margin-top: 30px;
  @supports (object-fit: cover) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@include media-breakpoint-up(xl) {
  .results-tab .game-card__score-extended, .upcoming-tab .game-card__score-extended {
    justify-content: space-around;
  }
}