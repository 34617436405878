.content_form {
    margin-top: 56px;
    margin-bottom: 56px;
    p {
        margin-bottom: 24px;
    }
    input {
        height: 60px;
    }
    input, textarea {
        background-color: #f0f0f0;
        font-size: 14px;
        font-weight: 500;
        color: #555555;
        border: none;
        width: 100%;
        padding: 10px 10px 10px 14px;
    }
    textarea {
        resize: none;
        height: 160px;
        margin-bottom: -5px;
    }
    .button {
        width: 156px;
    }
    .field {
        margin-bottom: 24px;
    }
    .submit button {
        padding: 12px 27px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #FFF;
        border-radius: 4px;
        text-align: center;
        align-self: center;
        transition: $transition;
        background-color: $secondary-color;
        border: 1px solid $secondary-color;
        height: 56px;
        display: inline-flex!important;
        align-items: center;
        justify-content: center;
        -webkit-appearance: button;
    }
}
.input-wrap {
    position: relative;
    margin-bottom: 8px;
    &--textarea {
        margin-bottom: 24px;
    }
    label {
        z-index: 1;
        transform: translate(24px, 18px) scale(1);
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        top: 0;
        left: 0;
        position: absolute;
        transform-origin: top left;
        color: $placeholder;
    }
    input:focus, textarea:focus {
        outline: none;
    }
    input {
        padding-top: 20px;
        padding-bottom: 4px;
    }
    textarea {
        margin-bottom: -4px;
        padding-top: 28px;
        padding-left: 24px;
    }
    &::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        pointer-events: none;
    }
    &::after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid $tertiary-color;
        pointer-events: none;
    }
    &.text-content {
        label {
        transform: translate(24px, 10px) scale(0.75);
        }
    }
    &.focus {
        label {
        transform: translate(24px, 10px) scale(0.75);
        color: $tertiary-color;
        }
        &::after {
        transform: scaleX(1);
        }
    }
}
.g-recaptcha {
    margin-bottom: 24px;
}
// Remove default number styling
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
}
.multiplechoice, .checkboxes {
    .input > div > label:not(:first-of-type) {
        
    }
}
.checkboxes, .multiplechoice {
    label {
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: block;
      cursor: pointer;
      white-space: nowrap;
      input {
          height: auto;
          width: 24px;
      }
    }
  }
  