.tiles {
  @include module-margins();
  &__heading {
    @include section-heading()
  }
  &__wrap {
    @extend .row;
    @extend .no-gutters;
  }
  .tile {
    // Stops it from targeting social tiles
    @include media-breakpoint-down(md) {
      background-image: none!important;
    }
  }
}

.standard-content {
  .tiles {
    &:first-of-type {
      margin-top: 0;
    }
  }
  .tiles__heading {
    font-weight: $h3-font-weight;
    color: $h3-color;
  }
  .tile {
    @extend .col-6;
    @extend .col-md-4;
    @extend .col-lg-4;
    padding: 0;

    &__heading {
      @include media-breakpoint-up(lg) {
        @include h2-base();
        text-align: center;
        margin-bottom: 16px;
        color: #fff;
        font-size: $player-profile-heading;
      }
    }
  }
}

.tile {
  @extend .col-6;
  @extend .col-sm-4;
  @extend .col-lg-3;
  padding: 0;
  &__image {
    margin-bottom: 16px;
    @extend .img-fluid;
  }
  &__sub-text {
    @include micro-heading();
    text-transform: none;
  }
  &__heading {
    transition: $transition!important;
  }
  &__overlay {
    @extend .col-12;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 32px;
    &:hover {
      .tile__heading {
        color: $primary-color;
      }
    }
  }
  &--social {
    @extend .col-6;
    @extend .col-sm-6;
    @extend .col-lg-3;

  }
}

@include media-breakpoint-down(md) {
  .hero + .tiles, .tiles + .cards {
    margin-top: 76px;
    margin-bottom: 52px;
  }
}

@include media-breakpoint-up(sm) {
  .tiles {
    &__container {
      @include make-container();
      @include make-container-max-widths();
    }
  }
}

@include media-breakpoint-up(lg) {
  .tile {
    height: 235px;
    z-index: 1;
    @include image-background();
    transition: $card-transition!important;
    box-shadow: $box-shadow-initial;
    &:hover {
      transform: scale(1.05);
      box-shadow: $box-shadow-hover;
      z-index: 9;
      .tile__overlay {
        opacity: 1;
      }
    }
    &__image {
      display: none;
    }
    &__overlay {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: rgba($secondary-color, 0.9);
      opacity: 0;
      transition: $card-transition;
      padding: 0 24px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
    &__heading {
      @include h2-base();
      text-align: center;
      margin-bottom: 16px;
      color: #fff;
      font-size: $player-profile-heading !important;
    }
    &__sub-text {
      color: #fff;
    }
  }
}

@include media-breakpoint-up(xl) {
  .tile {
    height: 288px;
  }
}
