.footer {
  padding-top: 42px;
  padding-bottom: 68px;
  background-color: $footer-background-color;
  position: relative;
  z-index: 2;
  a {
    transition: opacity $transition-speed;
  }
  .row:first-of-type {
    @include media-breakpoint-down(sm) {
      margin-bottom: 50px;
    }
    > * {
      display: block;
      font-size: 12px;
      font-weight: 600;
      line-height: 2;
      color: $footer-text-color;
      opacity: 0.5;
      @include media-breakpoint-down(sm) {
        &:not(:last-child) {
          margin-bottom: 7px;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .row:last-of-type {
    a {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      opacity: 0.5;
      &:hover {
        opacity: 0.8;
      }
    }
    svg {
      width: 32px;
      height: 32px;
      fill: #fff;
      transition: $transition;
    }
  }
}

@include media-breakpoint-up(md) {
  .footer {
    padding-top: 28px;
    padding-bottom: 28px;
    .row:first-of-type {
      > * {
        &:not(:last-child) {
          margin-right: 17px;
        }
      }
    }
    .row:last-of-type {
      a {
        margin-right: 0;
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
    }
  }
}
