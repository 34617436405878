.media-cards {
  @include module-margins();
  &__heading {
    @include section-heading()
  }
  &__wrap {
    @extend .row;
  }
}

.media-card {
  @extend .media;
  @extend .col-11;
  @extend .col-sm-6;
  @extend .col-md-4;
  @extend .col-lg-3;
  margin-bottom: 10px;
  &__image {
    width: 112px;
    height: 112px;
    margin-right: 24px;
    align-self: flex-start;
  }
  &__heading {
    margin: 0 0 12px;
    @extend h4;
    color: $section-heading-color;
  }
  &__link-heading, &__link {
    font-size: 12px;
    font-weight: 500;
  }
  &__link-heading {
    margin-bottom: 0;
    color: $highlight;
  }
  &__link {
    color: #000;
  }
  &__blurb {
    @extend h6;
  }
  &__body {
    @extend .media-body;
    align-self: center;
  }
}

@include media-breakpoint-up(sm) {
  .media-cards {
    @include module-margins();
    &__container {
      @include make-container();
      @include make-container-max-widths();
    }
  }
  .media-card {
    margin-bottom: 68px;
    flex-direction: column;
    &__image {
      width: 192px;
      height: 192px;
      margin: 0 0 32px 0;
    }
    &__body {
      align-self: flex-start;
    }
    &__heading, &__blurb {
      width: 192px;
    }
    &__blurb {
      margin-bottom: 10px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .media-cards .media-card {
    margin-bottom: 92px;
  }
}
