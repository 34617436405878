.comparison {
  @include module-margins();
  &__container {
    @include make-container();
    @include make-container-max-widths();
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }
  &__heading {
    margin-bottom: 35px;
  }
  &__separator {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: $section-heading-color;
  }
}

.comparison-item {
  height: 88px;
  width: 100%;
  display: flex;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  align-items: center;
  &__player-item {
    height: 86px;
    width: 90px;
  }
  &__player-name {
    font-weight: 600;
    font-size: 15px;
    color: $section-heading-color;
    line-height: 34px;
  }
  &__player-team {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
  &--home {
    padding-right: 32px;
    .comparison-item__player-item {
      margin-right: 32px;
      border-left: 4px solid $tertiary-color;
    }
    .comparison-item__stat {
      margin-left: auto;
    }
  }
  &--away {
    padding-left: 32px;
    .comparison-item__player-item {
      margin-left: 32px;
      border-right: 4px solid $secondary-color;
      order: 2;
    }
    .comparison-item__stat {
      margin-right: auto;
      order: 0;
    }
    .comparison-item__player {
      order: 1;
      text-align: right;
    }
  }
}

@include media-breakpoint-up(md) {
  .comparison {
    &__separator {
      flex: 0 0 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__row {
      &:not(:last-of-type) {
        margin-bottom: -1px;
      }
    }
  }
  .comparison-item {
    flex: 1;
    &--home {
      padding-right: 0;
    }
    &--away {
      padding-left: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .comparison {
    &__heading {
      margin-bottom: 45px;
    }
    &__separator {
      flex: 0 0 110px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .comparison {
    &__separator {
      flex: 0 0 130px;
    }
  }
}
