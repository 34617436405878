// Default image settings when used as a css background
@mixin image-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@mixin module-margins {
  margin-top: 100px;
  margin-bottom: 100px;
  @include media-breakpoint-down(sm) {
   margin-top: 60px;
   margin-bottom: 60px;
  }
  //@include media-breakpoint-up(md) {
  //  margin-top: 56px;
  //  margin-bottom: 56px;
  //}
  //@include media-breakpoint-up(lg) {
  //  margin-top: 64px;
  //  margin-bottom: 64px;
  //}
  //@include media-breakpoint-up(xl) {
  //  margin-top: 80px;
  //  margin-bottom: 80px;
  //}
}

@mixin module-padding {
  padding-top: 40px;
  padding-bottom: 40px;
  @include media-breakpoint-up(sm) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@mixin background-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin list-style {
  list-style: none;
  padding-left: 30px;
  li {
    position: relative;
    margin-bottom: 16px;
    &::before {
      content: "—";
      display: block;
      position: absolute;
      left: -30px;
      top: 0px;
    }
  }
}

@mixin teams {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  span {
    opacity: 0.1;
    font-size: 46px;
    font-weight: $h1-font-weight-desktop;
    line-height: 0.43;
    color: #000000;
    margin-left: 25px;
    margin-right: 25px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }
  @include media-breakpoint-up(xl) {
    span {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
}

@mixin h1-base {
  color: $h1-color;
  font-style: $h1-font-style;
  font-family: $h1-font-family-mobile;
  font-weight: $h1-font-weight-mobile;
  font-size: $h1-font-size-mobile;
  line-height: $h1-line-height-mobile;
  text-transform: $h1-text-transform-mobile;
  letter-spacing: $h1-letter-spacing-mobile;
  @include media-breakpoint-up(sm) {
    font-size: ($h1-font-size-mobile + $h1-font-size-desktop) * 0.45;
  }
  @include media-breakpoint-up(md) {
    font-size: ($h1-font-size-mobile + $h1-font-size-desktop) * 0.55;
  }
  @include media-breakpoint-up(lg) {
    font-family: $h1-font-family-desktop;
    font-weight: $h1-font-weight-desktop;
    font-size: $h1-font-size-desktop;
    line-height: $h1-line-height-desktop;
    text-transform: $h1-text-transform-desktop;
    letter-spacing: $h1-letter-spacing-desktop;
  }
}

@mixin h2-base {
  color: $h2-color;
  font-style: $h2-font-style-mobile;
  font-family: $h2-font-family-mobile;
  font-weight: $h2-font-weight-mobile;
  font-size: $h2-font-size-mobile;
  line-height: $h2-line-height-mobile;
  text-transform: $h2-text-transform-mobile;
  letter-spacing: $h2-letter-spacing-mobile;
  @include media-breakpoint-up(lg) {
    font-style: $h2-font-style-desktop;
    font-family: $h2-font-family-desktop;
    font-weight: $h2-font-weight-desktop;
    font-size: $h2-font-size-desktop;
    line-height: $h2-line-height-desktop;
    text-transform: $h2-text-transform-desktop;
    letter-spacing: $h2-letter-spacing-desktop;
  }
}

@mixin h3-base {
  color: $h3-color;
  font-style: $h3-font-style;
  font-family: $h3-font-family;
  font-weight: $h3-font-weight;
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

@mixin h4-base {
  color: $h4-color;
  font-style: $h4-font-style;
  font-family: $h4-font-family;
  font-weight: $h4-font-weight;
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

@mixin h5-base {
  font-size: 1.4rem;
  font-weight: 600;
}

@mixin h6-base {
  font-size: 1.2rem;
  font-weight: 600;
}

@mixin section-heading {
  margin-bottom: 35px;
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
  a {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    left: 8px;
    color: $body-text-color;
    &:hover {
      color: $section-heading-more-hover;
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
    a {
      left: 12px;
    }
  }
}

@mixin paragraph {
  color: $paragraph-color;
  font-weight: $paragraph-font-weight-mobile;
  font-size: $paragraph-font-size-mobile;
  line-height: $paragraph-line-height-mobile;
  @include media-breakpoint-up(lg) {
    font-size: $paragraph-font-size-desktop;
    line-height: $paragraph-line-height-desktop;
  }
}

@mixin paragraph-heading {
  color: $paragraph-heading-color;
  font-weight: $paragraph-heading-font-weight-mobile;
  font-size: $paragraph-heading-font-size-mobile;
  line-height: $paragraph-heading-line-height-mobile;
  @include media-breakpoint-up(lg) {
    font-size: $paragraph-heading-font-size-desktop;
    line-height: $paragraph-heading-line-height-desktop;
  }
}

@mixin micro-heading {
  color: $micro-heading-color;
  font-weight: $micro-heading-font-weight;
  font-size: $micro-heading-font-size;
  line-height: $micro-heading-line-height;
  letter-spacing: $micro-heading-letter-spacing;
  text-transform: $micro-heading-text-transform;
}

@mixin mega-heading {
  color: $mega-heading-color;
  font-style: $mega-heading-font-style;
  font-family: $mega-heading-font-family;
  font-weight: $mega-heading-font-weight;
  font-size: $mega-heading-font-size;
  line-height: $mega-heading-line-height;
  text-transform: $mega-heading-text-transform;
}


@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: flex;
        opacity: 0;
    }

    100% {
        display: flex;
        opacity: 1;
    }
}

$typography_min_width_val: 576;
$typography_max_width_val: 1200;
@mixin fluid-property($property, $max, $min: 0, $start: $typography_min_width_val, $end: $typography_max_width_val, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  @if $min == 0 {
    $min: round($max * 0.78);
  }
  $multiplier: (($max - $min) / ($end - $start)) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}
