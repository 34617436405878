.hero--banner-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  z-index: 0;
  overflow: hidden;

  &.hype {
    @include media-breakpoint-down(sm) {
      height: 400px;
    }
  }

  &.video {
    position: absolute;
    top:50%;
    left:50%;
    height:auto;
    width:auto;
    min-width:100%;
    min-height:100%;
    transform:translate(-50%, -50%);
    object-fit:cover;
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path d="M0 0h1v1H0V0z" fill="%23000000" /></svg>');
    background-repeat: repeat;
    background-size: 2px 2px;
    opacity: .4;
  }
}

.hero--hype {
  &.dim--heavy {
    .hero--banner-image {
      opacity: 0.4;
    }
  }
  &.dim--light {
    .hero--banner-image {
      opacity: 0.6;
    }
  }
  &.dim--no {
    .hero--banner-image {
      opacity: 1;
    }
  }
}

.banner-video__container {
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  overflow: hidden;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path d="M0 0h1v1H0V0z" fill="%23000000" /></svg>');
    background-repeat: repeat;
    background-size: 4px 4px;
    opacity: .6;
  }
}

.hero {
  background-color: $primary-color;
  @include image-background();
  position: relative;

  h1 {
    line-height: 0.92;
    max-width: 1100px;
    margin-left: 0;
  }
  &__tiles {
    background-color: #fff;
    padding-left: 15px;
    @extend .row;
    @extend .no-gutters;
    @include media-breakpoint-down(sm) {
      padding-top: 15px;
    }
    div:not(:first-of-type) {
      border-top: 2px solid rgba(#fff, 0.1);
    }
  }
  &__tile {
    background-color: $secondary-color;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @extend .col-12;
    @extend .col-md-4;
    padding-left: 0;
    padding-right: 0;
    &:not(:first-of-type) {
      border-top: 2px solid rgba(#fff, 0.1);
    }
    .hero__tile__heading, .hero__tile__blurb, .hero__tile__link {
      padding: 0 35px;
    }
    &__heading {
      color: #fff;
      margin-bottom: 5px;
    }
    &__blurb {
      color: #fff;
      opacity: 0.8;
      margin-bottom: 15px;
      max-width: 330px;
    }
    &__link {
      @include h6-base;
      color: #fff;
      opacity: 0.6;
      margin-top: auto;
    }
  }
  &__heading-container {
    position: static;
  }
  &__sponsor {
    position: absolute;
    width: 58.9px;
    top: 0;
    right: 15px;
    img {
      @include img-fluid();
    }
    &-title {
      display: none;
    }
  }
  &__filters {
    display: none;
  }
  &--sm {
    padding-top: 22px;
    padding-bottom: 26px;
    .hero__content {
      min-height: 150px;
      position: relative;
    }
    .hero__container {
    }
    .hero__heading {
      align-self: flex-end;
      > * {
        color: #fff;
        margin-bottom: 0;
      }
    }
    + section {
      @include media-breakpoint-down(md) {
        margin-top: 40px;
      }
    }
  }
  &--lg {
    h1, h1 a {
      font-size: 4.8rem;
    }
  }
  &--hype {
    .hero__content {
      position: relative;
      min-height: 400px;
      @include image-background();
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      p {
        color: #fff;
        margin-bottom: 20px;
        line-height: 1.5;
      }
      h1 {
        @include h1-base();
        margin-bottom: 15px;
      }
      .container > * {
        max-width: 368px!important;
      }
      @include media-breakpoint-down(xs) {
        .container {
          margin-left: -15px;
        }
      }
      @include media-breakpoint-down(md) {
        &::before {
          width: 100%;
          height: 250px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
          content: "";
        }
        .container {
          position: absolute;
          bottom: 24px;
          h1 {
            font-size: 4.8rem;
            margin-bottom: 10px;
          }
          p {
            font-size: 1.2rem;
            max-width: 316px !important;
            margin-bottom: 10px;
          }
          a {
            padding: 0;
            background-color: transparent;
            border: none;
            opacity: 0.6;
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
  }
  &--card {
    margin-bottom: -140px;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 60px;
  list-style-type: none;

  &__separator {
    height: 6px;
    opacity: 0.7;
    margin: auto 9px;
  }
  &__home {
    display: flex;
  }

  a {
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  li:before {
    content: "";
    width: 4px;
    height: 6px;
    opacity: 0.7;
    display: inline-block;
    margin: auto 9px;
      background: {
      image: url(/static/media/svg/arrow-right-breadcrumb.svg);
      repeat: no-repeat;
      position: center;
      size: 4px 6px;
    }
  }

  li:last-of-type {
    a {
      opacity: 1;
    }
  }
}

.hero {
  .nav-tabs {
    margin-top: 25px;
    height: 48px;
    border: 2px solid #fff;
    display: flex;
    border-radius: 50px;
    justify-content: space-between;
    align-items: center;
    padding-left: 2px;
    padding-right: 2px;
  }
  .nav-item {
    width: 32.5%;
    margin-bottom: 1px;
    a {
      height: 40px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 12px;
      color: #fff;
      transition: $transition;
      &.active {
        color: $dark-navy-blue;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .hero {
    &--sm {
      .hero__content {
        min-height: 180px;
      }
    }
    &--hype {
      .hero__content {
        height: 480px;
        padding-left: 0;
        padding-right: 0;
        .container > * {
          max-width: 368px!important;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero {
    &--sm {
      padding-bottom: 60px;
      .hero__content {
        min-height: 200px;
      }
    }
    &__heading--word-break {
      word-spacing: 9999999px;
      @include mega-heading();
    }
    &__tiles {
      padding-left: 0;
      position: relative;
      background-color: $secondary-color;
      div:not(:first-of-type) {
        border-top: none;
      }
    }
    &__tile {
      transition: $card-transition !important;
      cursor: pointer;
      z-index: 1;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: $box-shadow-initial;
      border-top: none !important;
      &:nth-last-of-type(2) {
        margin-left: -1px;
        margin-right: -1px;
      }
      &:hover {
        transform: scale(1.05);
        z-index: 8;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: $box-shadow-hover;
      }
      > * {
        padding: 0 25px;
      }
      &__heading {
        margin-bottom: 18px;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
        }
      }
      &__blurb {
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
      }
    }
    &__heading-container {
      position: relative;
    }
    &__sponsor {
      width: 92px;
      top: 5px;
      right: 15px;
      img {
        @include img-fluid();
      }
      &-title {
        opacity: 0.39;
        font-size: 0.7rem;
        font-weight: bold;
        letter-spacing: 0.3px;
        color: #ffffff;
        display: block;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 6px;
      }
    }
    &--hype {
      .hero__content {
        height: 480px;
        padding-left: 0;
        padding-right: 0;
        .container > h1 {
          max-width: 540px!important;
        }
      }
    }
    &--card {
      margin-bottom: -170px;
    }
  }
  .breadcrumbs {
    margin-bottom: 20px;
  }
  .banner-video__container {
    height: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .hero {
    padding-top: 90px;
    &--sm {
      padding-top: 140px;
      .hero__content {
        min-height: 140px;
      }
    }
    &--lg {
      background-size: cover;
      .hero__container {
        position: relative;
        top: 32px;
      }
      + section {
        padding-top: 56px;
      }
    }
    &__tile {
      padding-top: 36px;
      padding-bottom: 36px;
    }
    &__tiles {
      top: 24px;
    }
    &__container {
      @include make-container();
      @media (min-width: 992px) and (min-width: 992px) {
        max-width: 960px;
      }
      @media (min-width: 992px) and (min-width: 1200px) {
        max-width: 1170px;
      }
    }
    &--hype {
      padding-top: 0;
      .hero__container {
        top: 0;
        max-width: none!important;
        padding-left: 0;
        padding-right: 0;
      }
      .hero__content {
        height: 680px;
        .container > h1 {
          max-width: 600px!important;
        }
        .container {
          margin-top: 40px;
        }
        p {
          color: #fff;
          margin-bottom: 30px;
        }
        h1 {
          @include h1-base();
          margin-bottom: 25px;
        }
      }
      .hero__tiles {
        margin-right: auto;
        width: 930px;
        position: absolute;
        top: 560px;
        left: 50%;
        margin-left: -465px;
      }
    }
    &--season-two {
      .hero__content {
        height: 770px;
        .container {
          position: relative;
          margin-top: 0;
          min-height: 456px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          h1 {
            max-width: 602px;
          }
          a {
            opacity: 1;
          }
          p {
            max-width: 221px!important;
            opacity: 0.68;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 22px;

          }
          > * {
            position: relative;
            z-index: 1;
          }
        }
      }
      #slider {
        z-index: 0;
        position: absolute;
        bottom: -50px;
        left: 15px;
        max-width: none!important;
        width: calc(100% + ((100vw - 930px)/2));
        .slide {
          height: 456px;
          width: 930px;
          display: flex;
          align-items: flex-end;
          margin-right: 40px;
          margin-left: 40px;
          &:focus {
            outline: none;
          }
        }
        .slide__wrap {
          width: 750px!important;
          height: 100%;
          position: absolute;
          bottom: 0px;
          left: 0;
          transition: all ease-in-out 0.3s;
        }
        .slide__teams {
          display: flex;
          margin-top: 33px;
          margin-bottom: 50px;
          align-items: center;
          img {
            max-width: 48px;
            max-height: 48px;
          }
        }
        .slide__score--score {
          margin-left: 16px;
          margin-right: 16px;
          font-weight: 700;
          font-size: 30px;
          text-align: center;
          line-height: 20px;
          font-size: 30px;
          color: rgba(255,255,255,0.30);
          text-align: center;
          line-height: 20px;
          strong {
            color: #FFFFFF;
          }
        }
        .slick-current, .slick-clone-current {
          .slide__wrap {
            left: calc(930px - 750px);
          }
          .slide__content {
            > h1, > a {
              opacity: 1!important;
              transition: opacity 0.15s linear 0.3s!important;
            }
            > p {
              opacity: 0.65!important;
              transition: opacity 0.15s linear 0.3s!important;
            }
          }
        }
        .slide__content {
          > h1, > p, > a {
            position: relative;
            z-index: 1;
            opacity: 0!important;
            transition: opacity 0.15s linear 0s!important;
          }
          > p {
            margin-top: 15px;
            margin-bottom: 33px;
          }
        }
        .carousel-indicators, .slick-dots {
          bottom: 0;
          right: 0;
          width: 930px;
          justify-content: flex-start;
          margin: 49px 0 3px 0;
          li {
            height: 2px;
            width: 56px;
            border-radius: 0;
            &:first-of-type {
              margin-left: calc(930px - 750px);
            }
          }
        }
      }
    }
    &--card {
      margin-bottom: -215px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero {
    &--lg {
      .hero__container {
        position: relative;
        top: 48px;
      }
      + section {
        padding-top: 56px;
      }
      .slider__slides {
        width: 810px;
      }
      .slide {
        width: 810px;
      }
      .slide__wrap {
        height: 456px;
      }
      .carousel-indicators {
        margin-right: -30px;
      }
    }
    &--lg {
      + section {
        padding-top: 100px;
      }
    }
    &__tile {
      padding-top: 44px;
      padding-bottom: 40px;
    }
    &__tiles {
      top: 52px;
      height: 256px;
      .hero__tile__heading, .hero__tile__blurb, .hero__tile__link {
        padding: 0 36px;
      }
      .hero__tile__link {
        margin-bottom: 22px;
      }
    }
    &--hype {
      .hero__container {
        top: 0;
      }
      .hero__content {
        height: 800px;
      }
      .hero__tiles {
        margin-right: auto;
        width: 1140px;
        position: absolute;
        top: 650px;
        left: 50%;
        margin-left: -570px;
      }
    }
    &--season-two {
      .hero__content {
        height: 900px;
      }
      #slider {
        width: calc(100% + ((100vw - 1140px)/2));
        .slider__slides {
          width: calc(100% + ((100vw - 1140px)/2));
        }
        .slide {
          margin-right: 60px;
          margin-left: 60px;
          width: 1040px;
        }
        .slick-active, .slick-clone-current {
          .slide__wrap {
            left: calc(1040px - 750px);
          }
        }
        .carousel-indicators, .slick-dots {
          width: 1040px;
          li {
            &:first-of-type {
              margin-left: calc(1040px - 750px);
            }
          }
        }
      }
    }
  }
}
