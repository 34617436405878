.contact {
  @include module-padding();
  h4 {
    margin-bottom: 15px;
  }
  #map {
    width: 100%;
    height: 500px;
  }
  &__information {
    margin-bottom: 40px;
    .row {
      margin-bottom: 30px;
    }
    a {
      display: block;
    }
    .btn {
      display: inline-block;
      margin-top: 5px;
    }
    .contact__name {
      font-size: 1.4rem;
      font-weight: 600 !important;
      line-height: 1.43;
      color: #939393;
    }
    a:first-of-type {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.43;
      color: #939393;
    }
    a:last-of-type {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.43;
      color: $tertiary-color;
    }
    span:last-of-type {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.43;
      color: #939393;
    }
    h4 {
      @include make-col(12);
      margin-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
    div div {
      @include make-col(12);
      position: relative;
      top: 2px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  &__form {
    margin-bottom: 56px;
    p {
      margin-bottom: 24px;
    }
    input#insertEastwoodEasterEggHere {
      display: none;
    }
    input {
      height: 60px;
    }
    input, textarea {
      background-color: $off-white;
      font-size: 14px;
      font-weight: 500;
      color: #555555;
      border: none;
      width: 100%;
      padding: 10px 10px 10px 24px;
    }
    textarea {
      resize: none;
      height: 160px;
    }
  }
  &__map {
    padding-left: 0!important;
    padding-right: 0!important;
    img {
      @include img-fluid();
      width: 100%;
    }
  }
  .contact__subscribe-button {
    margin-top: 18px;
    max-width: 165px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .input-wrap {
    position: relative;
    margin-bottom: 8px;
    &--textarea {
      margin-bottom: 24px;
    }
    label {
      z-index: 1;
      transform: translate(24px, 18px) scale(1);
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      top: 0;
      left: 0;
      position: absolute;
      transform-origin: top left;
      color: $placeholder;
    }
    input:focus, textarea:focus {
      outline: none;
    }
    input {
      padding-top: 20px;
      padding-bottom: 4px;
    }
    textarea {
      margin-bottom: -4px;
      padding-top: 28px;
      padding-left: 24px;
    }
    &::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }
    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid $tertiary-color;
      pointer-events: none;
    }
    &.text-content {
      label {
        transform: translate(24px, 10px) scale(0.75);
      }
    }
    &.focus {
      label {
        transform: translate(24px, 10px) scale(0.75);
        color: $tertiary-color;
      }
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .contact {
    &__map {
      padding-left: 15px!important;
      padding-right: 15px!important;
    }
  }
}

@include media-breakpoint-up(md) {
  .contact {
    img {
      position: relative;
      top: 6px;
    }
    &__form {
      order: 5;
    }
  }
}

@include media-breakpoint-up(lg) {
  .contact {
    &__information {
      margin-bottom: 20px;
      h4 {
        margin-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
        @include make-col(4);
      }
      div div {
        @include make-col-offset(1);
        @include make-col(6);
        position: relative;
        top: 2px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}
