.sponsors {
  @include module-padding();
  background-color: $off-white;
  &__heading {
    @include section-heading;
    padding-left: 0!important;
    padding-right: 0!important;
  }
  &__sub-heading {
    margin-bottom: 16px;
    &:not(:first-of-type) {
      margin-top: 32px;
    }
  }
  &__container {
    //overflow-y: scroll;
  }
  &__tier-one-container {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      .sponsors__logo-outer {
        width: calc(33.33% - 3.33px);
        flex: 0 0;
        flex-basis: calc(33.33% - 3.33px);
        @include fluid-property('height', 90, 60, 320, 575);
        margin-bottom: 5px;
      }
      .sponsors__logo-outer:nth-of-type(3n) {
        margin-right: 0;
      }
      .sponsors__logo-outer .sponsors__logo-container {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__scroll-container--no-tiers {
    // width: 696px;
    display: flex;
    flex-wrap: wrap;
    .sponsors__logo-outer:nth-of-type(6n) {
      // margin-right: 0;
    }
    .sponsors__logo-container {
      margin-right: 5px;
    }
  }
  &__scroll-container, &__slide {
    @media (max-width: 767px) {
      // width: 696px;
      display: flex;
      flex-wrap: wrap;
      .sponsors__logo-outer:nth-of-type(6n) {
        // margin-right: 0;
      }
    }
  }
  .sponsors__logo-outer {
    margin-right: 5px;
  }
  &__logo-container {
    display: flex;
    width: 111.7px;
    height: 66.7px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
    padding: 10px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    img {
      opacity: 0.8;
      max-width: 80%;
      max-height: 90%;
      height: auto;
      transition-property: opacity;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      will-change: opacity;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .sponsors__tier-one-container, .sponsors__scroll-container--no-tiers {
    width: 696px;
    .sponsors__logo-outer:nth-of-type(6n) {
      margin-right: 0;
    }
  }
  .sponsors {
    &__container {
      overflow-y: visible;
    }
    &__scroll-container {
      width: 696px;
      position: relative;
    }
    &__slide {
      display: flex!important;
      flex-wrap: wrap;
      .sponsors__logo-outer:nth-of-type(6n) {
        margin-right: 0;
      }
    }
    .slick-dots, .slick-next, .slick-prev {
      position: absolute;
    }
    .slick-prev {
      display: none!important;
    }
    .slick-next {
      border: none;
      background-color: transparent;
      top: -48px;
      right: 0;
      width: 32px;
      height: 32px;
      padding: 0;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .slick-dots {
      width: auto;
      list-style: none;
      right: 32px;
      display: flex;
      padding: 0;
      margin: 0;
      top: -41px;
      align-items: center;
      .slick-active {
        button::before {
          opacity: 1;
          width: 8px;
          height: 8px;
        }
      }
      button {
        background-color: transparent;
        border: none;
        width: 18px;
        height: 18px;
        padding: 5px;
        position: relative;
        font-size: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        &::before {
          width: 6px;
          height: 6px;
          content: '';
          background-color: $body-text-color;
          border-radius: 100%;
          transition: $transition;
          opacity: 0.3;
          position: absolute;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .sponsors {
    &__scroll-container, &__tier-one-container {
      width: 100%;
    }
    &__slide {
      width: 100%;
    }
    &__logo-container {
      width: 138px;
      height: 87px;
      margin-bottom: 20px;
    }
    .sponsors__logo-outer {
      margin-right: 20px;
    }
    .sponsors__logo-outer:nth-of-type(6n) {
      margin-right: 0;
    }
  }
}


@include media-breakpoint-up(xl) {
  .sponsors {
    &__logo-container {
      width: 165px;
      height: 100px;
      margin-bottom: 30px;
    }
    .sponsors__logo-outer {
      margin-right: 30px;
    }
    .sponsors__logo-outer:nth-of-type(6n) {
      margin-right: 0;
    }
  }
}
