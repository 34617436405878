.switch {
  height: 48px;
  width: 100%;
  border-radius: 22px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  &::before {
    content: "";
    height: 40px;
    border-radius: 22px;
    background-color: $tertiary-color;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: all ease-in-out 0.4s;
  }
  
  .nav-item {
    height: 40px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    a.nav-link {
      background-color: transparent;
      padding: 0;
      border: none;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      color: $body-text-color;
      transition: $transition;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: $tertiary-color;
      }
      &.active {
        color: #fff!important;
      }
    }
    &:not(.rugby) {
      width: 50%;
    }
  }
  &[data-switch="left"]:not(.rugby) {
    &::before {
      left: 4px;
      width: 50%;
    }
    .nav-item:first-child a {
      color: #fff;
    }
  }
  &[data-switch="right"]:not(.rugby) {
    &::before {
      left: calc(50% - 4px);
      width: 50%;
    }
    .nav-item:last-child a {
      color: #fff;
    }
  }
  &.rugby {
    margin-bottom: 80px;
    @include media-breakpoint-up(lg) {
      width: 50% !important;
    }
    ul {
      flex-basis: 100%;
      display: flex;
      flex-flow: row nowrap;
    }
    .nav-item {
      align-items: center;
      justify-content: center;
      position: relative;
      flex-flow: column nowrap;
      flex: 1 1 100%;
      a.nav-link {
        border-radius: 22px;
        &.active {
          background-color: $tertiary-color;
        }
      }
    }
  }
}
