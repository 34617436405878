.promotional {
  margin-top: 36px;
  margin-bottom: 62px;
  .lead {
    max-width: 847px;
    margin-bottom: 40px;
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@include media-breakpoint-up(md) {
  .promotional {
    margin-top: 56px;
    margin-bottom: 100px;
    .lead {
      margin-bottom: 60px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .promotional {
    margin-top: 76px;
    margin-bottom: 150px;
    .lead {
      margin-bottom: 80px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .promotional {
    margin-bottom: 210px;
    .lead {
      margin-bottom: 95px;
    }
  }
}
