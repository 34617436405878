.btn {
  padding: 10px 42px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 600;
  transition: $transition;
  &:hover {
    color: #fff!important;
  }
  &--outline {
    background-color: transparent;
    border: 2px solid $body-text-color;
    color: $body-text-color;
    &:hover {
      border: 2px solid #000;
      color: #000!important;
    }
  }
  &-secondary, &--secondary {
    transition: background-color ease 0.3s!important;
    color: #fff!important;
    border: none;
    background-color: $tertiary-color;
    &:hover {
      background-color: $tertiary-color-hover;
      //border: $tertiary-color-hover 1px solid;
    }
    &.btn--play {
      &::before {
        background-image: url("/static/media/svg/play-icon-white.svg");
      }
    }
  }
  &--loading {
    background-color: #B3B3B3;
    opacity: 0.33;
  }
  &--sm, &-sm {
    padding: 10px 24px;
  }
  &--play, &--pdf, &--ticket, &--location-pin, &--calendar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 40px;
    &::before {
      content: '';
      height: 22px;
      width: 22px;
      @include image-background;
      background-size: 24px;
      display: block;
      border-radius: 100%;
      margin-right: 6px;
      margin-left: -8px;
      position: relative;
      bottom: 1px;
    }
  }
  &--play {
    &::before {
      background-image: url("/static/media/svg/play-icon.svg");
      height: 10px;
      width: 8px;
      background-size: unset;
      border-radius: 0;
      margin-right: 10px;
    }
  }
  &--pdf {
    &::before {
      background-image: url("/static/media/svg/pdf.svg");
    }
  }
  &--ticket {
    &::before {
      background-image: url("/static/media/svg/ticket.svg");
    }
  }
  &--location-pin {
    &::before {
      background-image: url("/static/media/svg/location-pin.svg");
    }
  }
  &--calendar {
    &::before {
      background-image: url("/static/media/svg/calendar.svg");
    }
  }

  &--load-more {
    width: 150px;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;

    &#loading {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
