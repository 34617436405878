.footer-banner {
  background-color: $primary-color;
  @include image-background();
  background-size: cover;
  background-position: center center;
  position: relative;
  padding-top: 60px;
  padding-bottom: 54px;
  z-index: 2;
  &::before {
    @include background-overlay();
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38));
  }
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path d="M0 0h1v1H0V0z" fill="%23000000" /></svg>');
    background-repeat: repeat;
    background-size: 2px 2px;
    opacity: .2;
  }
  &__container {
    @extend .container;
    z-index: 1;
    position: relative;
  }
  &__heading {
    @include h1-base();
    margin-bottom: 22px;
  }
  &__top {
    padding-bottom: 64px;
    @extend .row;
    &__col {
      @extend .col-12;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
  .footer-banner__logo {
    width: 90px;
    height: 90px;
    margin: 0 auto 30px;
  }
  .btn-secondary {
    display: flex;
    align-self: center;
    &:first-of-type {
      margin-bottom: 5px;
    }
  }
  hr {
    margin-bottom: 44px;
    height: 0px;
    opacity: 0.32;
    border: solid 1px #979797;
  }
  p {
    opacity: 0.4;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    color: #ffffff;
    max-width: 333px;
    margin-bottom: 20px;
  }
  &__bottom {
    @extend .row;
    a {
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      opacity: 0.9;
      transition: $transition;
      &:hover {
        opacity: 1;
      }
    }
    div:first-of-type {
      margin-bottom: 60px;
      @extend .col-12;
      @extend .col-lg-6;
    }
    img {
      margin-bottom: 20px;
      height: 42px;
    }
    div:not(:first-of-type) {
      @extend .col-6;
      @extend .col-lg-3;
      p {
        font-size: 11px;
      }
    }
    div:last-of-type {
      img {
        height: 48px;
        margin-bottom: 14px;
        position: relative;
        bottom: 2px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .footer-banner {
    padding-top: 132px;
    padding-bottom: 78px;
    &__heading {
      margin-bottom: 37px;
    }
    &__top {
      padding-bottom: 145px;
      &__col {
        display: block;
      }
    }
    &__logo {
      width: 106px;
      height: 106px;
      margin-bottom: 35px;
    }
    hr {
      margin-bottom: 48px;
    }
    .btn-secondary {
      display: inline-block;
      &:first-of-type {
        margin-right: 9px;
        margin-bottom: 0;
      }
    }
  }
}
