.ladder {
  @include module-margins;
  padding-top: 64px;
  margin-top: 42px;
  &__section-heading {
    margin-bottom: 60px;
  }
  &__headings {
    height: 64px;
    border-bottom: 1px solid #e0e0e0;
    th {
      position: relative;
      padding-left: 5px;
      padding-right: 5px;
      border: none;
      span {
        position: absolute;
        transform: rotate(-90deg);
        top: -15px;
        display: inline-block;
        width: 20px;
        left: 50%;
        margin-left: -10px;
        @include micro-heading();
      }
    }
  }
  &__ladder {
    @extend .table;
    margin-bottom: 80px;
  }
  &-team {
    &--loading {
      .ladder-team__logo {
        border-radius: 7px;
        background-color: #EBEBEB;
      }
    }
  }
  &__heading {
    border-bottom: none;
  }
  &__body {
    text-align: center;
    .ladder-team__logo {
      max-height: 40px;
      max-width: 40px;
      height: 40px;
      width: 40px;
    }
    .ladder-team {
      td:nth-child(n + 7) {
        font-weight: 700;
      }
      // Adds off white background color to first 4
      &:nth-last-child(n + 5) {
        background-color: $off-white;
      }
      // Adds finals tag under 4th row
      &:nth-child(5) td:last-child {
        position: relative;
        &::after {
          content: "Finals";
          height: 20px;
          width: 52px;
          background-color: $dark-navy-blue;
          font-size: 10px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0;
          top: -10px;
        }
      }
      td:first-of-type {
        text-align: left;
        padding-left: 0;
      }
    }
    td, th {
      vertical-align: middle;
      font-size: 1.4rem;
      font-weight: 500;
      padding-top: 22px;
      padding-bottom: 22px;
      &.bold {
        font-weight: 700;
      }
    }
    th, td {
      font-weight: 700;
      border-top: none;
      border-bottom: 1px solid #E0E0E0;
    }
  }
  .ladder-team--highlight {
    background-color: $secondary-color!important;
    color: #fff;
    h4 {
      color: #fff;
    }
  }
  p:not(:last-of-type) {
    margin-bottom: 3rem;
  }
  h4 {
    margin-bottom: 3rem;
  }
  &__points-allocation {
    .col-6 {
      div {
        margin-bottom: 10px;
      }
      &:first-of-type {
        div {
          font-weight: 600;
        }
      }
    }
  }
  &__ladder-changes {
    margin-bottom: 56px;
  }

  &.rugby {
    .ladder-team {
      &:nth-last-child(n + 5) {
        background-color: transparent;
      }
      // Remove finals tag under 4th row
      &:nth-child(5) td:last-child {
        &::after {
          content: "";
          display: none;
        }
      }
    }
  }
}

.key {
  margin-top: 15px;
  margin-bottom: 56px;
  &__item {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 100%;
      margin-right: 8px;
    }
    &--greyish::before {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

@include media-breakpoint-up(lg) {
  .ladder {
    margin-top: 80px;
    padding-top: 0;
    &__section-heading {
      margin-bottom: inherit;
    }
    &__headings {
      height: 64px;
      border-bottom: 1px solid #e0e0e0;
      th {
        text-align: center;
        height: 62px;
        vertical-align: middle!important;
        width: 90px;
        &:nth-of-type(1) {
          width: 60px;
        }
        &:nth-of-type(2) {
          width: 350px;
        }
        span {
          position: static;
          transform: none;
          top: auto;
          width: auto;
          margin-left: 0;
        }
      }
    }
    &__body {
      .ladder-team__logo {
        max-height: 48px;
        max-width: 48px;
        height: 48px;
        width: 48px;
        margin-right: 42px;
      }
      td, th {
        font-size: 1.6rem;
        font-weight: 500;
        padding-top: 20px;
        padding-bottom: 20px;
        &.bold {
          font-weight: 700;
        }
      }
      tr td:first-of-type {
        padding-left: 0.75rem;
      }
    }
    &-team--loading {
      &:nth-of-type(even) {
        background-color: #F4F4F4;
      }
      .ladder-team__logo {
        width: 60px;
        height: 60px;
        max-height: 60px;
        max-width: 60px;
      }
    }
    &__ladder-changes {
      margin-bottom: 0;
    }
    .key {
      margin-bottom: 80px;
    }
  }
}
