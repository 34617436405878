.stats-block {
  &__heading {
    margin-bottom: 35px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    img {
      max-width: 56px;
      max-height: 56px;
    }
  }
  &--player-comparison {
    .stats-block__header {
      img {
        width: 80px;
        height: 80px;
        max-width: 80px;
        max-height: 80px;
        border-bottom: 4px solid;
        margin-bottom: 7px;
      }
      div {
        font-weight: 600;
        font-size: 15px;
        color: $section-heading-color;
        line-height: 34px;
      }
      .stats-block__away-image-wrap div {
        text-align: right;
      }
    }
  }
  + .stats-block {
    margin-top: 40px;
  }
}

@include media-breakpoint-up(md) {
  .stats-block {
    + .stats-block {
      margin-top: 48px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .stats-block {
    &__heading {
      margin-bottom: 48px;
    }
    + .stats-block {
      margin-top: 64px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .stats-block {
    + .stats-block {
      margin-top: 80px;
    }
  }
}
