.game-tile {
  background-color: $off-white;
  &__header {
    background-color: $primary-color;
    padding: 17px 20px;
    font-weight: 700;
    font-size: 10px;
    color: #FFFFFF;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
  &__home {
    margin-top: 8px;
  }
  &__away {
    margin-bottom: 8px;
  }
  &__away, &__home {
    padding: 4px 16px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: $primary-color;
    line-height: 32px;
    img {
      max-width: 32px;
      max-height: 32px;
      width: 100%;
      height: 100%;
      margin-right: 9px;
    }
  }
  &__score {
    margin-right: 8px;
  }
  &__result {
    border-top: 1px solid #E0E0E0;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: $primary-color;
    line-height: 20px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 13px;
    }
    &--loss {
      color: $body-text-color;
    }
  }
}

@include media-breakpoint-between(xs, sm) {
  .game-tile {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-up(md) {
  .game-tile {
    flex: 0 0;
    flex-basis: calc(50% - 3px);
    &__wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

@include media-breakpoint-between(md, md) {
  .game-tile {
    &:nth-of-type(n + 3) {
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .game-tile {
    flex-basis: calc(25% - 4.5px);
  }
}
