.stats-card {
  background-color: $off-white;
  padding: 32px 23px;
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;
    img {
      max-width: 40px;
      max-height: 40px;
    }
  }
  &__heading {
    margin-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  &--player-comparison {
    .stats-card__header {
      img {
        width: 80px;
        height: 80px;
        max-width: 80px;
        max-height: 80px;
        border-bottom: 4px solid;
        margin-bottom: 7px;
      }
      div {
        font-weight: 600;
        font-size: 15px;
        color: $section-heading-color;
        line-height: 34px;
      }
      .stats-card__away-image-wrap div {
        text-align: right;
      }
    }
  }
}

.stat {
  width: 100%;
  margin-top: 16px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 700;
      font-size: 16px;
      color: $primary;
      letter-spacing: 0.44px;
      line-height: 1;
      top: 3px;
      position: relative;
    }
  }
  &__heading {
    font-size: 1.5rem;
  }
  &__bar {
    width: 100%;
    height: 4px;
    position: relative;
    background-color: #EBEBEB;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__bar-wrap {
    width: calc(50% - 1px);
    height: 4px;
    position: relative;
  }
  &__bar-home, &__bar-away {
    position: absolute;
    height: 100%;
    width: 0%;
    transition: width ease-in-out 0.3s;
  }
  &__bar-home {
    right: 0;
  }
  &__bar-away {
    left: 0;
  }
}

@include media-breakpoint-up(md) {
  .stats-card {
    padding: 56px 94px 72px;
    &__header {
      margin-bottom: 40px;
      img {
        max-width: 56px;
        max-height: 56px;
      }
    }
    &__heading {
      padding-left: 32px;
      padding-right: 32px;
      margin-top: 10px;
      @include h3-base();
      color: $section-heading-color;
    }
  }
}
