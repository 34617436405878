.feature-links {
  @include module-margins();
  &__container {
    @include make-container();
    @include make-container-max-widths()
  }
  &__heading {
    @include section-heading;
  }
  &__wrap {
    @extend .row;
  }
}

.feature-link {
  &:hover {
    z-index: 5;
    .feature-link__image {
      transform: scale(1.05);
    }
    .feature-link__text{
      //box-shadow: $box-shadow-hover;
      //transform: translateY(-10px);
      h4, h3, h2 {
        color: $secondary-color;
      }
    }
  }
  &__image-wrap {
    overflow: hidden;
  }
  &__image {
    @include image-background();
    transition: $transition;
    transition-duration: 0.3s;
    width: 100%;
    &-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-top: -25px;
        height: 90px;
        width: 90px;
        position: absolute;
        opacity: 0.9;
        transition: $card-transition;
      }
    }
  }
  &__play {
    display: none;
  }
  &__heading {
    color: $primary-color;
  }
  &__text {
    width: 87%;
    padding: 24px;
    position: relative;
    bottom: 56px;
    background-color: white;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    transition: $card-transition;
    transition-duration: 0.3s;
    .feature-link__heading {
      transition: $card-transition;
      transition-duration: 0.3s;
    }
    a {
      font-size: 12px;
      font-weight: 600;
      color: #555;
    }
    .btn {
      align-self: flex-start;
      margin-top: 14px;
      margin-bottom: -8px;
    }
  }
  &__blurb {
    margin-bottom: 19px;
  }
  &--video {
    &:hover {
      .feature-link__image::before {
        opacity: 1;
      }
      .feature-link__image-wrap img {
        opacity: 1;
      }
    }
    .feature-link__play {
      display: block;
    }
  }
  &--overlay {
    .feature-link__image::before {
      @include background-overlay();
      background-image: linear-gradient(146deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6));
      transition: $transition;
    }
  }
  &--xs {
    height: 240px;
    .feature-link__heading {
      color: $section-heading-color;
    }
    .feature-link__image {
      img {
        height: 70px;
        width: 70px;
      }
    }
    .feature-link__text {
      width: 76%;
      bottom: 50px;
      padding: 16px;
      h5 {
        color: #000;
        line-height: 1.42;
        margin-bottom: 0;
      }
    }
    .feature-link__image {
      height: 192px;
    }
  }
  &--small {
    @extend .col-12;
    @extend .col-lg-4;
    .feature-link__image {
      height: 280px;
    }
    .feature-link__text {
      bottom: 48px;
      padding: 24px 24px 36px;
      width: 75%;
      margin-bottom: -48px;
    }
    .feature-link__heading {
      margin-bottom: 6px;
      color: $section-heading-color;
    }
    .feature-link__title {
      @include micro-heading();
      margin-bottom: 8px;
    }
  }
  &--medium {
    @extend .col-12;
    @extend .col-lg-6;
    .feature-link__image {
      height: 280px;
    }
    .feature-link__text {
      min-height: 122px;
    }
    .feature-link__blurb {
      margin-bottom: 5px;
    }
    .feature-link__heading {
      color: $section-heading-color;
      margin-bottom: 15px;
    }
  }
  &--large {
    @extend .col-12;
    .feature-link__image {
      height: 304px;
    }
    &__heading {
      margin-bottom: 15px;
    }
  }
  &--promotional {
    margin-bottom: 40px;
    .feature-link__heading {
      color: $section-heading-color;
    }
    .feature-link__image {
      height: 260px;
    }
    .feature-link__text {
      width: 100%;
      bottom: 0;
      padding: 26px 32px 25px;
      .btn {
        margin-bottom: 0;
      }
    }
    .feature-link__heading {
      margin-bottom: 16px;
    }
    &:hover {
      .feature-link__heading {
        color: $highlight;
      }
    }
  }
  &--left {
    .feature-link__image-wrap {
      margin-left: auto;
    }
    .feature-link__text {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  .feature-links {
    &__container {
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .news__container .feature-link--small .feature-link__play {
    width: 40px !important;
    height: 40px !important;
    margin-top: 0 !important;
  }
}

@include media-breakpoint-up(md) {
  .feature-link {
    &__text {
      width: 75%;
    }
    &--xs {
      height: 222px;
    }
    &--large {
      .feature-link__image {
        height: 360px;
      }
      .feature-link__text {
        width: 75%;
        bottom: 66px;
      }
    }
    &--medium {
      margin-bottom: 80px;
      .feature-link__image {
        height: 340px;
      }
      .feature-link__text {
        position: absolute;
        bottom: -15px;
        right: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .feature-link {
    position: relative;
    &--xs {
      height: 210px;
      .feature-link__image {
        height: 144px;
      }
      .feature-link__text {
        bottom: 45px;
      }
    }
    &--large {
      .feature-link__heading {
        margin-bottom: 24px;
      }
      .feature-link__text {
        padding: 40px;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 458px;
      }
      .feature-link__image-wrap {
        width: 83%;
      }
      .feature-link__image {
        width: 100%;
        height: 440px;
      }
    }
    &--medium {
      margin-bottom: 100px;
      &:nth-of-type(even) {
        padding-left: 5px!important;
        .feature-link__text {
          right: 15px;
        }
      }
      &:nth-of-type(odd) {
        padding-right: 5px!important;
      }
      .feature-link__image {
        width: 100%;
        height: 360px;
      }
      .feature-link__text {
        width: 83%;
        min-height: 180px;
        padding: 32px 32px 15px;
        bottom: -50px;
        right: 5px;
      }
    }
    &--small {
      .feature-link__image {
        height: 230px;
      }
      .feature-link__text {
        width: 250px;
      }
      &:nth-of-type(3n) {
        padding-left: 6.66px;
      }
      &:nth-of-type(3n - 1) {
        padding-left: 3.33px;
        padding-right: 3.33px;
      }
      &:nth-of-type(3n - 2) {
        padding-right: 6.66px;
      }
    }
    &--promotional {
      margin-bottom: 80px;
    }
    &--left {
      .feature-link__text {
        right: auto;
        left: 0;
      }
      .feature-link__image {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .feature-link {
    &--promotional {
      margin-bottom: 130px;
    }
    &--large {
      .feature-link__image-wrap {
        width: 848px;
      }
      .feature-link__image {
        width: 848px;
      }
    }
    &--medium {
      .feature-link__image {
        height: 410px;
      }
      .feature-link__text {
        width: 470px;
      }
    }
    &--small {
      .feature-link__image {
        height: 280px;
      }
      .feature-link__text {
        width: 283px;
      }
    }
  }
}
