.news-details, .match-report {
  .hero {
    padding-bottom: 105px;
    margin-bottom: -75px;
  }
  &__display-image {
    @include img-fluid();
  }
  &__video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 0px;
    /* size of chrome */
    height: 0;
    overflow: hidden;
  }
  &__video-container iframe,
  &__video-container object,
  &__video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
  }
  .news-details__date, .match-report__social {
    font-size: 14px;
    font-weight: bold;
    color: $dark-navy-blue;
  }
  .news-details__social, .match-report__social {
    svg {
      height: 32px;
      width: 32px;
    }
  }
  &__heading {
    margin-bottom: 24px;
  }
  .lead {
    margin-bottom: 35px;
  }
  &__content {
    margin-bottom: 110px;
    p {
      font-weight: 400;
      line-height: 1.63;
      margin-bottom: 35px;
      font-size: 1.6rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &.standard-content {
      margin-top: 0;
    }
  }
  &__more-news {
    margin-bottom: 100px;
    &-wrap {
      @include media-breakpoint-down(md) {
        position: static!important;
      }
    }
  }
  &__image-wrap {
    position: relative;
    margin-bottom: 44px;
    .news-details__play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 120px;
      height: 120px;
      margin-left: -60px;
      margin-top: -60px;
    }
  }
  &__more-videos {
    .news-details__heading {
      @include section-heading();
    }
  }
}

@include media-breakpoint-up(lg) {
  .news-details {
    .hero {
      padding-bottom: 275px;
      margin-bottom: -235px;
    }
    &__image-wrap {
      margin-bottom: 80px;
      .news-details__play-icon {
        width: 170px;
        height: 170px;
        margin-left: -85px;
        margin-top: -85px;
      }
    }
    &__more-videos {
      .row {
        margin-right: -2px;
        margin-left: -2px;
        .col-lg-4 {
          padding-left: 2px!important;
          padding-right: 2px!important;
        }
      }
    }
    &__social h5 {
      color: #000;
      margin-right: 8px;
    }
    &__header {
      margin-bottom: 42px;
    }
    &__content {
      margin-bottom: 190px;
      p {
        font-size: 18px;
        line-height: 1.56;
      }
    }
    &__more-news-wrap {
      width: 290px;
    }
    &.news-details--video {
      .news-details__content {
        margin-bottom: 60px;
      }
      .news-details__more-news {
        margin-bottom: 140px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .news-details__more-news-wrap {
    width: 262px;
  }
}
