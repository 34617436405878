.news {
  margin-bottom: 90px;
  .feature-link__title {
    @include micro-heading();
  }
  .feature-link--large {
    margin-bottom: 56px;
    .feature-link__heading {
      margin-bottom: 16px;
    }
    .feature-link__blurb {
      margin-bottom: 16px;
    }
  }
  @include media-breakpoint-down(md) {
    .feature-link--large {
      margin-bottom: 0;
    }
    .feature-link--small {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
      display: flex;
      .feature-link__image {
        width: 80px;
        height: 80px!important;
      }
      .feature-link__image-wrap {
        margin-right: 16px;
      }
      .feature-link__text {
        padding: 0!important;
        position: static;
        margin-bottom: 0;
        justify-content: center;
        align-items: flex-start;
        margin-left: 0;
        a {
          display: none;
        }
        .feature-link__heading {
          font-size: 12px;
          font-weight: bold;
          color: $dark-navy-blue;
        }
        .feature-link__title {
          margin-bottom: 8px;
        }
      }
    }
    &__container .row:first-of-type {
      margin-bottom: -14px;
    }
  }
  &__load-more {
    margin-top: 48px;
  }
  .hero__sponsor {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
  .news {
    &__container {
      @include make-container();
      @include make-container-max-widths();
    }
  }
}

@include media-breakpoint-up(lg) {
  .news {
    margin-bottom: 170px;
    .nav-tabs {
      display: none!important;
    }
    .hero {
      margin-bottom: -152px;
      padding-bottom: 196px;
      &__content {
        min-height: 170px!important;
      }
      &__heading-container {
        display: flex;
        justify-content: space-between;
      }
      &__filters {
        display: flex;
        align-items: center;
        .custom-select {
          height: 48px;
          background-color: transparent;
          border-radius: 25px;
          border: 2px solid #fff;
          padding-left: 20px;
          padding-right: 32px;
          color: #fff;
          background-image: url('/static/media/svg/arrow-down.svg');
          background-size: 12px 12px;
          background-position: right 1.75rem center;
          font-size: 12px;
          font-weight: 600;
          min-width: 160px;
          max-width: 280px;
          cursor: pointer;

          option {
            color: black;
          }
        }
      }
      &__sponsor {
        display: block;
        top: 125px;
      }
    }
    .feature-link--large {
      margin-bottom: 0;
    }
    .feature-link--small {
      &:nth-last-of-type(n + 4) {
        margin-bottom: 80px;
      }
    }
    &__container {
      .row:first-of-type {
        margin-bottom: 80px;
      }
    }
  }
}
