.slide {
  @include image-background();
  &::before {
    @include background-overlay;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38));
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 24px;
    height: 400px;
    position: relative;
  }
  &__footer {
    position: relative;
    padding: 0 15px 24px;
    cursor: pointer;
    &:hover {
      a {
        opacity: 1;
      }
    }
  }
  h1, a {
    position: relative;
  }
  h1, h1 a {
    margin-bottom: 10px;
    opacity: 1;
  }
  a {
    @include h6-base();
    color: $slider-title-color-mobile;
    opacity: 0.9;
    transition: $transition;
  }
  &__thumbnail {
    width: 84px;
    height: 56px;
    position: absolute;
    bottom: 6px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: $transition-fade;
  }
}

.slick-dots li:hover {
  .slide__thumbnail {
    opacity: 1;
  }
}

.carousel-indicators, .slick-dots {
  bottom: 18px;
  justify-content: flex-end;
  margin-left: 15px;
  margin-right: 15px;
  li {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 4px;
    margin-left: 4px;
    cursor: pointer;
    background-color: rgba($slider-dots-color, 0.4);

    &.active {
      background-color: $slider-dots-color;
    }
  }
}

.slick-dots {
  padding-left: 0;
  list-style: none;
  display: flex;
  li {
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
    &::before {
      transition: width linear 3.1s;
      width: 0%;
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
    }
    &.slick-active {
      &::before {

        width: 100%;
        background-color: #fff;
        height: 100%;
      }
    }
  }
  button {
    content: "";
    font-size: 0;
    padding: 0;
    border: none;
    background-color: transparent!important;
    width: 100%;
    height: 300%;
    position: absolute;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    top: -2px;
  }
}

@include media-breakpoint-up(lg) {
  .slide {
    &__wrap {
      min-height: 426px;
    }
    &::before {
      display: none;
    }
    &__footer {
      padding: 19px 60px 15px 30px;
      &::before {
        @include background-overlay;
        opacity: 0.9;
        background-color: $slider-bg-color;
      }
    }
    h1 {
      margin-bottom: 4px;
    }
    a {
      color: $slider-title-color;
    }
  }
  .carousel-indicators, .slick-dots {
    bottom: 9px;
  }
}

@include media-breakpoint-up(xl) {
  .carousel-indicators, .slick-dots {
    li {
      height: 6px;
      width: 6px;
    }
  }
}
