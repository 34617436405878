.stats {
  padding-top: 15px;
  &__display-image {
    width: 100%;
    margin-bottom: 35px;
  }
  &__position {
    font-size: 14px;
    font-weight: bold;
    color: $dark-navy-blue;
    margin-bottom: 16px;
  }
  p.lead {
    margin-bottom: 16px;
  }
  section {
    margin-bottom: 56px;
  }
  h4 {
    margin-bottom: 14px;
  }
  &__fun-facts {
    ul {
      @include list-style();
    }
  }
  &__statistics {
    font-size: 13px;
    font-weight: 600;
    color: $body-text-color;
    margin-bottom: 15px;
  }
  &__statistic {
    margin-bottom: 32px;
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
    div:first-child {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 15px;
      line-height: 1;
    }
    div:last-child {
      font-size: 72px;
      font-weight: bold;
      line-height: 0.82;
      color: $tertiary-color;
    }
  }
  &__social {
    a {
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &__player-links {
    .media {
      align-items: center;
    }
    img {
      width: 80px;
      height: 80px;
      margin-right: 15px;
    }
    h5 {
      color: $dark-navy-blue;
      margin-bottom: 12px;
      font-weight: 700;
    }
    p {
      @include micro-heading();
      margin-bottom: 0;
    }
  }
  &__numbers .row {
    margin-bottom: 5px;
  }
  &__next, &__previous {
    width: 56px;
    height: 56px;
    border: solid 2px $body-text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    svg {
      width: 12px;
      height: 12px;
      fill: $body-text-color;
    }
  }
}

@include media-breakpoint-up(md) {
  .stats {
    padding-top: 66px;
    padding-bottom: 134px;
    &__paginate {
      margin-top: 13px;
      a:first-child {
        margin-right: 8px;
      }
    }
    &__intro {
      display: flex;
      flex-direction: column;
      padding-top: 260px;
    }
    &__display-image {
      margin-bottom: 66px
    }
    .stats__statistics {
      margin-bottom: 0;
    }
    .stats__social {

    }
    &__block {
      display: flex;
      flex-wrap: wrap;
      section {
        margin-bottom: 100px;
      }
      > section {
        flex: 0 0 100%;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .stats {
    &__display-image {
      margin-bottom: 108px;
      //margin-top: -130px;
    }
    .stats__intro {
      margin-bottom: 100px;
    }
    &__top-row {
      margin-top: -108px;
    }
    &__paginate {
      margin-bottom: 0;
    }
    &__statistics .row .row {
      margin-top: 8px;
    }
    &__numbers .row {
      position: relative;
      top: 1px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .stats {
    &__top-row {
      margin-top: -246px;
    }
    &__block {
      justify-content: space-between;

      > section {
        flex: 0 0 50%;
      }
    }
  }
}
