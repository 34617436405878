$secondary-color-svg: $secondary-color + '';
$secondary-color-svg: str-replace($secondary-color-svg, '#', '%23');
$primary-color-svg: $primary-color + '';
$primary-color-svg: str-replace($primary-color-svg, '#', '%23');

.standard-content {
  margin-top: 30px;
  margin-bottom: 78px;
  .side-nav {
    margin-top: -30px;
  }
  h2, h3 {
    @include paragraph-heading();
    margin-bottom: 16px;
  }
  h4 {
    @include paragraph();
    font-weight: bold;
  }
  p {
    @include paragraph();
    margin-bottom: 1.6em;
  }
  img {
    margin-top: 5px;
    margin-bottom: 40px;
    @include img-fluid();
  }
  ul, ol {
    @include paragraph();
    margin-top: 0.6em;
    margin-bottom: 2.2em;
    padding: 0;
    padding-left: 40px;
    li {
      margin-bottom: 1em;
      padding-left: 7px;
      padding-right: 60px;
    }
  }
  a {
    color: $secondary-color;
    &:hover {
      color: $primary-color;
    }
  }
  .iframe-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      max-width: none;
    }
  }
  .embed-wrapper {
    iframe {
      max-width: none!important;
    }
  }
  .content-actions {
    display: block;
    margin-top: 40px;
    list-style-type: none;
    border-top: 1px solid $border-color;
    padding-left: 0;

    li {
      margin: 0;
      border-bottom: 1px solid $border-color;
      padding-right: 0;

      &:before {
        content: "";
        padding-right: 0;
      }

      a {
        display: block;
        font-weight: 600;
        padding: 3rem 6rem 3rem 0;
        background-repeat: no-repeat;
        background-position: center right 10px;
        background-size: 9px 15px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 28"><polygon id="path-1" fill="#{$secondary-color-svg}" fill-rule="nonzero" points="18 14 3.6 28 3.1653064e-15 24.5 10.8 14 0 3.5 3.6 0"/></svg>');

        &:hover {
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 28"><polygon id="path-1" fill="#{$primary-color-svg}" fill-rule="nonzero" points="18 14 3.6 28 3.1653064e-15 24.5 10.8 14 0 3.5 3.6 0"/></svg>');

        }
      }
    }
  }
  table {
    @extend .table;
    @extend .table-bordered;
    thead th {
      background-color: $off-white;
      border: 1px solid #e0e0e0;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.4px;
      color: $dark-navy-blue;
      height: 46px;
      text-transform: uppercase;
    }
    tbody tr {
      font-size: 14px;
      line-height: 1.14;
      color: #555555;
      height: 62px;
    }
  }
  .table-bordered th, .standard-content table th, .table-bordered td, .standard-content table td {
    border: 1px solid #e0e0e0;
  }
  .table th, .standard-content table th, .ladder__ladder th, .table td, .standard-content table td, .ladder__ladder td {
    vertical-align: middle;
    padding-left: 24px;
    padding-right: 24px;
  }
  .table thead th, .standard-content table thead th, .ladder__ladder thead th {
    vertical-align: middle;
  }

  .gameday {
    margin-top: 0;
  }
}

@include media-breakpoint-down(sm) {
  .standard-content {
    img {
      margin-top: 5px;
      margin-bottom: 40px;
      max-width: 100%;
      width: auto!important;
      height: auto!important;
    }
  }
}

@include media-breakpoint-up(md) {
  .standard-content {
    [style*="float:right"] {
      margin: 0 0 0 25px!important;
      img {
        margin: 35px 0;
      }
    }
    [style*="float:left"] {
      margin: 0 25px 0 0!important;
      img {
        margin: 35px 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .standard-content {
    margin-top: 82px;
    margin-bottom: 112px;
    h2, h3, h4 {
      margin-bottom: 23px;
    }
    img {
      margin-top: 35px;
      margin-bottom: 65px;
    }
    .side-nav {
      margin-top: 7px;
    }

    .gameday {
      margin-top: 40px;
    }
  }
}

