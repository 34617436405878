[data-nav="off"] {

  .navbar {
    height: 85px;
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      .navbar-brand--full {
        display: block;
        animation: fadeInFromNone 0.3s linear;
		    -webkit-animation-fill-mode: forwards;
      }
      .navbar-brand--simple {
        display: none;
      }
    }
  }
}

[data-nav-m="on"] {
  overflow: hidden;
}

[data-nav="white"] {

  .navbar {
    height: 70px;
    background-color: $nav-background-color;
    border-bottom: 1px solid $off-white;
    box-shadow: 0 4px 12px rgba(0,0,0,0.04);
    .nav-link {
      color: $nav-text-color;
    }
    svg {
      fill: $nav-text-color;
    }
    .nabar__right--contact {
      path {
        fill: $nav-text-color;
      }
    }
    .nabar__right--store {
      path {
        stroke: $nav-text-color;
      }
    }
    .nabar__right--member {
      path {
        stroke: $nav-text-color;
        &:last-of-type {
          fill: $nav-text-color;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .navbar-brand {
        .navbar-brand--full {
          display: none;
        }
        .navbar-brand--simple {
          
        }
      }
    }

    + .mobile-nav {
      top: 70px;
    }
    .search {
      top: 70px;
    }
  }
}

.navbar {
  // padding-top: 11.5px;
  // padding-bottom: 11.5px;
  height: 85px;
  // border-bottom: 1px solid $off-white;
  transition-property: height,background-color;
  transition-duration: 0.2s ;
  transition-timing-function: linear;
  will-change: height,background-color;
  box-shadow: 0 4px 12px rgba(0,0,0,0);

  .navbar-brand {
    width: 80px;
    .navbar-brand--full {
      max-height: 80px;
      max-width: 80px;
      height: 100%;
      margin-top: 12px;
    }
    .navbar-brand--simple {
      max-height: 40px;
      max-width: 60px;
      height: 100%;
    }
  }
  .container-fluid {
    display: block;
  }
  &.fixed {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 5000;
  }
}

.navbar-toggler {
  .menu {
    display: none;
  }
  .search-open {
    display: none;
  }
  &.collapsed {
    .search-close {
      display: none;
    }
    .search-open {
      display: block;
    }
    .menu {
      display: block;
    }
    .menu-close {
      display: none;
    }
  }
}


@include media-breakpoint-down(md) {

  .navbar {
    height: 70px !important;
    background-color: $nav-background-color !important;

    .navbar-brand {
      width: 60px;
      .navbar-brand--full {
        display: none !important;
      }
      .navbar-brand--simple {
        display: block !important;
        animation: fadeInFromNone 0.2s linear;
		    animation-delay: 0.2s;
		    -webkit-animation-fill-mode: forwards;
      }
    }
  }
  .mobile-nav {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-fixed;
    display: none;
    background-color: $mobile-menu-background-color;
    transition: all $transition-speed;
    @include image-background();
    &::before {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38));
      @include background-overlay();
    }
    .navbar-nav {
      width: 100%;
      height: 100%;
      position: relative;
      list-style: none;
      overflow: scroll;
      padding-bottom: 90px;
    }
    li {
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(#fff, 0.2)
      }
    }
    a {
      height: 70px;
      width: 100%;
      position: relative;
      justify-content: space-between;
      @include h2-base();
      display: flex;
      align-items: center;
      padding-left: 21px!important;
      padding-right: 21px!important;
      color: $mobile-menu-text-color;
    }
    .dropdown__menu ul {
      list-style: none;
    }
    .dropdown__menu a {
      font-size: 22px;
      font-weight: 600;
      height: 54px;
    }
  }
  .nav-item--store {
    
  }
  .nav-item--extra {
    border: none!important;
    margin-top: 32px;
    a {
      font-size: 18px;
      font-weight: 600;
      height: 20px;
      padding-left: 15px!important;
    }
  }
  .nav-text {
    opacity: 0.5;
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    margin-top: 32px;
  }
  .dropdown__menu {
    display: none;
    padding-bottom: 40px;
  }
  .toggle-dropdown__close {
    display: none;
  }
  .dropdown {
    &.open {
      background-color: $off-white;
      a {
        color: #000;
      }
      > a {
        color: $tertiary-color!important;
      }
      .toggle-dropdown__open {
        display: none;
      }
      .toggle-dropdown__close {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .mobile-nav {
    display: none!important;
  }

  .navbar {
    border-bottom: none;
    .navbar-toggler__search {
      display: inline-block;
      padding: 0;
      border: none;
    }
    .container-fluid {
      @include make-container-max-widths();
    }
    .navbar-brand {
      position: relative;
      img {
        transition: $transition;
      }
      .navbar__logo--small {
        position: absolute;
        left: 50%;
        margin-left: -40px;
        top: 3px;
        height: 81px;
        width: 81px;
        opacity: 0;
      }
      .navbar__logo--big {
        height: 81px;
        position: relative;
        top: 13px;
        opacity: 1;
      }
    }
    &__right {
      display: flex!important;
      align-items: center;
      position: relative;
      height: 24px;
      .search-open {
        margin-right: 0!important;
      }
      a {
        display: flex;
      }
    }
    .nav-link {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: normal;
      color: $nav-text-color;
      @if $nav-is-transparent == true {
        color: $nav-transparent-text-color;
      }
      opacity: 0.9;
      transition: $transition;
      &:hover {
        opacity: 1;
      }
    }
    .toggle-dropdown {
      display: none;
    }
    .navbar-nav .nav-link {
      padding-left: 8px;
      padding-right: 8px;
    }
    svg {
      fill: #fff;
      transition: $transition;
      cursor: pointer;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
    .dropdown__menu {
      display: none;
      position: absolute;
      padding: 15px 0 0;
      top: 100%;
      left: 16px;
      ul {
        list-style: none;
        background-color: $nav-background-color;
        padding: 20px 0 20px 0;
        border-radius: 8px;
        box-shadow: 2px 2px 26px 0 rgba(0, 0, 0, 0.2);
      }
      li {
        & > a {
          transition: color $transition-speed;
          white-space: nowrap;
          font-size: 1.4rem;
          font-weight: 600;
          line-height: normal;
          letter-spacing: normal;
          padding: 0 50px 0 30px;
          display: block;
          &:hover, &:focus, &.active, &:active {
            color: $highlight;
          }
        }
        &:not(:last-of-type) {
          a {
            padding-bottom: 13px;
          }
        }
      }
      &::before {
        position: absolute;
        display: block;
        opacity: 1;
        top: 9px;
        left: 29px;
        height: 0;
        width: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        -webkit-transition: opacity $transition-speed;
        transition: opacity $transition-speed;
      }
    }
    .nabar__right--contact {
      margin-right: 12px;
      path {
        fill: #fff;
      }
    }
    .nabar__right--store {
      margin-right: 12px;
      path {
        stroke: #fff;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    .navbar-nav .nav-link {
      padding-left: 16px;
      padding-right: 16px;
    }
    &__right {
      margin-right: 16px;
      a:last-child {
        margin-right: 0;
      }
    }
  }
}
