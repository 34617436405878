.standard-content .faq {
  padding-top: 0;
  padding-bottom: 50px;

  .faq__container {
    padding: 0;
  }
}

.faq {
  @include module-padding();
  .card-header {
    padding: 34px 15px 34px 39px;
    background-color: $off-white;
    border: none;
    cursor: pointer;
    position: relative;
    transition: background-color ease-in-out 0.1s 0.2s, border ease-in-out 0.1s 0.2s, padding ease-in-out 0.1s 0.1s;
    &:hover {
      h5 {
        color: $highlight;
      }
      svg {
        fill: $highlight;
      }
    }
    button {
      padding: 0;
    }
    h5 {
      color: $section-heading-color;
      margin-right: 60px;
      position: relative;
      transition: $transition;
      div {
        position: absolute;
        top: 0;
        left: -24px;
      }
    }
    svg {
      position: absolute;
      height: 24px;
      width: 24px;
      right: 4px;
      top: 31px;
      transition: $transition;
    }
    &::after {
      content: "";
      position: absolute;
      height: 24px;
      width: 24px;
      right: 4px;
      top: 31px;
    }
    &.collapsed {
      border-bottom: 1px #e0e0e0 solid;
      &::after {
        background-image: url("/static/media/svg/plus.svg");
      }
    }
    &:not(.collapsed) {
      background-color: $secondary-color;
      padding-bottom: 26px;
      transition:none;
      transition: padding ease-in-out 0.01s;
      h5 {
        color: #fff;
        transition:none;
      }
      svg {
        display: none;
        transition:none;
      }
      &::after {
        background-image: url("/static/media/svg/minus-white.svg");
      }
      + div {
        border-bottom: 1px solid rgba($secondary-color, 0.5)
      }
    }
  }
  .card {
    border: none;
  }
  .card-body {
    padding: 8px 15px 30px 39px;
    color: #fff !important;
    background-color: $secondary-color;
    p {
      margin-bottom: 30px;
      color: #fff !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a {
      color: #fff !important;
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-up(sm) {
  .faq {
    &__container {
      @include make-container();
      @include make-container-max-widths();
    }
    .card-header {
      padding: 34px 40px 34px 64px;
      &::after, svg {
        right: 15px;
      }
    }
    .card-body {
      padding: 8px 40px 2px 64px;
      > * {
        max-width: 630px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .faq {
    padding-top: 56px;
    padding-bottom: 90px;
    .card-header {
      padding: 34px 40px 34px 80px;
      h5 {
        div {
          left: -40px;
        }
      }
      &::after, svg {
        right: 28px;
      }
    }
    .card-body {
      padding: 20px 80px 30px 80px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .faq {
    padding-top: 85px;
    padding-bottom: 150px;
    .card-header {
      padding: 40px 40px 40px 80px;
      h5 {
        font-size: 1.8rem;
      }
      &::after, svg {
        width: 32px;
        height: 32px;
        background-size: 32px;
        top: 36px;
      }
    }
    .card-body {
      padding-bottom: 60px;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.56;
      }
    }
  }
}
