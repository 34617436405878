.game-row {
  &__teams {
    @include teams();
    margin-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 0;

    img {
      width: 80px;
      height: 80px;
    } 
  }
  @include media-breakpoint-down(md) {
    .container .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__information {
    font-size: 14px;
    line-height: 1.57;
    color: #555555;
    font-weight: 400;
    .game-row__title {
      font-weight: 600;
    }
    a {
      color: $tertiary-color;
      transition: color $transition-speed;
      margin-top: 5px;
      font-size: 12px;
      font-weight: 600;
      &:hover {
        color: #ff3a88;
      }
    }
    .col-6 {
      position: relative;
      img {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  &__button-wrap {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 5;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0, 0.3);
    a:first-of-type {
      margin-right: 15px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .game-row {
    margin-top: 52px;
    & > .container {
      padding-bottom: 35px;
      border-bottom: 1px solid #dee2e6;
    }
    &__teams {
      border-bottom: 0;
      margin: 0;
      padding-bottom: 0;
      justify-content: space-between;
      img {
        height: 120px;
        width: 120px;
      }
    }
    &__information {
      position: relative;
      top: 10px;
      .col-6 {
        padding-left: 53px!important;
        display: flex;
        flex-direction: column;
        div {
          margin-bottom: 3px;
        }
        img {
          width: 40px;
          height: 40px;
          top: 18px;
        }
        .game-row__location-pin {
          left: 6px
        }
        .game-row__title {
          margin-top: 20px;
        }
      }
    }
    + .standard-content {
      margin-top: 0;
      .side-nav {
        margin-top: 0;
        padding-left: 0!important;
        + div {
          margin-top: 35px;
        }
        &__menu {
          border-top: none;
        }
      }
    }
  }
}
