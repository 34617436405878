
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: $placeholder;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: $placeholder;
  opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: $placeholder;
  opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder;
}
:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color: $placeholder;
}

html {
  font-size: 62.5%; /* font-size 1em = 1px on default browser settings */
}

[v-cloak] {
  display: none;
}

.color {
  &--primary {
    color: $primary-color;
  }
  &--secondary {
    color: $secondary-color;
  }
  &--tertiary {
    color: $tertiary-color!important;
  }
}

body {
  background-color: $body-background-color;
  font-family: $base-font-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $body-text-color;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  padding-top: 0;

  @include media-breakpoint-down(md) {
    padding-top: 70px;
  }
}

h1 {
  @include h1-base();
}

h2 {
  @include h2-base();
  color: $primary-color;
}

h3 {
  @include h3-base();
  color: $section-heading-color;
}

h4 {
  @include h4-base();
  color: $section-heading-color;
}

h5 {
  @include h5-base();
  margin-bottom: 12px;
  color: #fff;
  line-height: 1.4;
}

h6 {
  @include h6-base();
  color: $dark-navy-blue;
}

a {
  color: $a-tag-start-color;
  text-decoration: none;
  outline: none;
  transition: color $transition-speed;
  border: none;
  cursor: pointer;
  &:hover, &:focus, &:active {
    text-decoration: none!important;
    transition: color $transition-speed;
  }
}

.container--start-later {
  @include media-breakpoint-up(sm) {
    @include make-container();
    @include make-container-max-widths();
  }
}

p {

}

p.lead {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: $body-text-color;
  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

strong {
  font-weight: 600;
}

body.active {
  overflow: hidden;
}

.tooltip {
  top: 5px!important;
  z-index: 5001;
  &-inner {
    background-color: $tertiary-color;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    color: $tooltip-text-color;
    padding: 5px 10px 5px;
  }
  .arrow::before {
    border-bottom-color: $tertiary-color;
  }
}

html.scroll-lock, body.scroll-lock {
  overflow: hidden;
}

.highlight {
  color: $secondary-color!important;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height $transition-speed;
  -o-transition: height $transition-speed;
  transition: height $transition-speed;
}

.module-margins {
  @include module-margins;
}


::selection {
  background: $secondary-color;
  color: white;
}
::-moz-selection {
  background: $secondary-color;
  color: white;
}
