@keyframes blip{
  0% { opacity:1; -webkit-transform:scale(0); transform:scale(0); }
  0.1% { opacity:1; -webkit-transform:scale(.1); transform:scale(.1); }
  25% { opacity:0.7; }
  50% { opacity:0; -webkit-transform:scale(1.3); transform:scale(1.3); }
  50.1% { opacity:1; -webkit-transform:scale(.1); transform:scale(.1); }
  75% { opacity:0.7; }
  100% { opacity:0; -webkit-transform:scale(1.3); transform:scale(1.3); }
}

.game-card {
  padding-top: 40px;
  padding-bottom: 36px;
  background-color: #fff;
  text-align: center;
  transition: background-color $transition-speed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    background-color: darken(#fff, 5%);
  }
  &__fixture-heading {
    display: none;
  }
  &__live {
    height: 19px;
    margin-top: 5px;
    transition: all $card-transition;
    width: 80px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  &__live-indicator, &__live-text {
    height: 100%;
    width: auto;
  }
  &__live-text {
    margin-left: 6px;
  }
  &__live-indicator {
    opacity: 0;
    animation-name: blip;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  &__sub-heading {
    margin-top: 13px;
    display: none;
  }
  &__heading {
    margin-bottom: 36px; 

    &.rugby {
      margin-bottom: 10px;
      p {
        font-size: 1.2rem;
      }
    }
  }
  
  &__teams {
    @include teams();
    img {
      max-width: 80px;
    }
    &--resulted {
      display: none;
    }
  }
  &__wrap {
    width: 100%;
  }
  .btn {
    padding: 0 20px;
    height: 40px;
    min-width: 154px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  &__date-time {
    .game-card__result-image {
      display: none;
    }
  }
  &__date {
    margin-bottom: 40px;
    padding: 0 30px;
  }
  .btn:first-of-type {
    margin-right: 6px;
  }
  &__home {
    &.game-card--live {
      border: 4px solid $tertiary-color;
    }
    &--win, &--loss, &--draw {
      position: relative;
      &::before, .game-card__result {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        width: 38.7px;
        height: 38.6px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 15px;
        right: 15px;
        position: absolute;
      }
    }
    &:hover {
      background-color: #fff;
    }
    .game-card__live {
      height: 23px;
      width: 98px;
    }
    .game-card__teams .game-card__score--v {
      font-weight: 900;
      font-size: 46px;
      margin-left: 24px;
      margin-right: 24px;
      opacity: 1!important;
    }
    .game-card__teams {
      span {
        opacity: 1;
        color: rgba(0, 0, 0, 0.1);
      }
      span strong {
        color: $primary-color;
      }
    }
    .game-card__heading {
      h3, h4 {
        @extend h3;
      }
      margin-bottom: 26px;
    }
  }
  &__button-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__prev, &__next {
    position: absolute;
    top: 85%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__prev {
    left: 32px;
  }
  &__next {
    right: 32px;
  }
  &--hero-preview {
    .game-card__score--score {
      font-weight: 700;
      color: #E0E0E0!important;
      text-align: center;
      line-height: 20px;
      display: flex;
      span {
        font-weight: 900!important;
        font-size: 46px!important;
        color: #B3B3B3!important;
        text-align: center;
        line-height: 20px;
        display: flex;
      }
    }
    .bonus__heading {
      display: none;
    }
    .bonus__points {
      span {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  &--loading {
    .game-card {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &__button-wrap {
        margin-top: 40px;
        @include media-breakpoint-between(lg, lg) {
          .btn {
            margin-bottom: 6px;
            margin-right: 0;
          }
        }
      }
      &__heading {
        width: 110px;
        height: 8px;
        border-radius: 8px;
        background-color: #D8D8D8;
        opacity: 0.56;
        margin-left: auto;
        margin-right: auto;
      }
      &__team-one, &__team-two {
        opacity: 0.51;
        background: rgba(216,216,216,0.56);
        border-radius: 8px;
        width: 73px;
        height: 66px;
      }
      &__time-loading {
        width: 110px;
        height: 4px;
        border-radius: 8px;
        margin-bottom: 6px;
        background-color: #D8D8D8;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .game-card__teams .game-card__score--v {
      opacity: 0.1;
      font-weight: 900;
      font-size: 46px;
      margin-left: 44px;
      margin-right: 44px;
    }
  }
  &--report {
    background-color: $off-white;
    .game-card {
      &__heading {
        margin-bottom: 2px;
      }
      &__teams {
        margin-top: 24px;
        margin-bottom: 6px;
        img {
          max-height: 64px;
          max-width: 64px;
        }
        span {
          font-size: 32px;
          font-weight: 700;
          text-align: center;
          line-height: 20px;
          font-size: 32px;
          color: #B3B3B3;
          text-align: center;
          line-height: 20px;
          opacity: 1;
          strong {
            color: $primary;
            font-weight: 700;
          }
        }
      }
    }
    .btn {
      max-width: 188px;
      margin-left: auto;
      margin-right: auto!important;
    }
  }
  &--hero {
    position: relative;
    .bonus .bonus__heading {
      margin-bottom: 0!important;
    }
  }
}

.bonus {
  &__points {
    margin-bottom: 24px;
  }
  &__point {
    font-weight: 700;
  }
  &__point-separator {
    margin-left: 12px;
    margin-right: 12px;
  }
  &__heading .bonus__point {
    display: none;
  }
}

.fixtures .game-card__button-wrap {
  padding-top: 42px;
}

@mixin game-card-report-large {
  .bonus {
    margin-top: -8px;
    &__points {
      display: none;
    }
    &__heading {
      margin-bottom: 40px;
      > span {
        display: inline-block;
        &:nth-of-type(2) {
          margin-left: 36px;
          margin-right: 36px;
        }
      }
      .bonus__point {
        display: inline-block;
      }
    }
  }
  .game-card {
    &--report {
      padding-top: 60px;
      padding-bottom: 60px;
      height: auto;
      .btn {
        min-width: 180px;
      }
      .game-card {
        &__heading {
          @include h3-base();
          margin-bottom: 8px;
          color: $section-heading-color;
        }
        &__teams {
          margin-top: 34px;
          margin-bottom: 0;
          height: 109px;
          min-height: 109px;
          img {
            max-width: 109px;
            max-height: 109px;
            width: 100%;
            height: 100%;
          }
          span {
            font-size: 72px;
            color: #B3B3B3;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .game-card {
    &__prev, &__next {
      top: 50%;
    }
  }
}

@include media-breakpoint-between(md, md) {
  @include game-card-report-large();
}

@include media-breakpoint-up(md) {
  .game-card {
    &--hero-preview {
      .bonus__heading {
        display: block;
      }
      .game-card__score--score {
        margin-left: 32px;
        margin-right: 32px;
        span {
          margin-left: 32px;
          margin-right: 32px;
        }
        div {
          &:last-of-type {
            position: relative;
            left: 6px;
          }
        }
      }
    }
  }
  &--draw {
    &::before, .game-card__result, .game-card__result::before {
      content: "D";
      background-color: $greyish;
    }
  }
}

@include media-breakpoint-up(lg) {
  @include game-card-report-large;
  .game-card {
    padding-top: 0;
    padding-bottom: 0;
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button:first-of-type {
        margin: 0 0 10px 0;
      }
    }
    &__team-one.winner, &__team-two.winner {
      > div:last-of-type, > div:last-of-type span {
        color: $primary;
      }
    }
    &__button-wrap {
      flex-direction: column;
      @include media-breakpoint-down(lg) {
        .btn:first-of-type {
          margin-right: 0;
          margin-bottom: 6px;
        }
      }
    }
    &__home {
      img {
        max-height: 64px;
        max-width: 64px;
      }
      .game-card__teams {
        span {
          font-size: 35px;
          font-weight: 700;
          margin-left: 8px;
          margin-right: 8px;
          strong {
            font-weight: 700;
          }
        }
      }
    }
    &__prev, &__next {
      width: auto;
      height: auto;
    }
    h2 {
      padding: 0 20px;
      font-size: 42px;
    }
    &--hero {
      padding-top: 72px;
      padding-bottom: 82px;
      .game-card {
        height: 417px;
        &__teams {
          width: 100%;
          min-height: 168px;
          margin-top: 24px;
          img {
            max-height: 168px;
            max-width: 168px;
          }
        }
      }
      .bonus {
        margin-top: -18px;
      }
    }
    &--hero-preview {
      .game-card__score--score {
        margin-left: 48px;
        margin-right: 48px;
        span {
          margin-left: 48px;
          margin-right: 48px;
        }
      }
      .bonus__heading {
        span:nth-of-type(2) {
          margin-left: 52px;
          margin-right: 52px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  @include game-card-report-large;
  .game-card {
    height: 380px;
    &__button-wrap {
      flex-direction: row;
    }
    .btn {
      padding: 11px 20px;
    }
    &__home {
      img {
        max-height: 72px;
        max-width: 72px;
      }
      .game-card__teams {
        span {
          font-size: 40px;
          font-weight: 700;
          margin-left: 10px;
          margin-right: 10px;
          strong {
            font-weight: 700;
          }
        }
      }
    }
    &--hero {
      height: 417px;
      padding-top: 72px;
      padding-bottom: 82px;
      .game-card {
        &__teams {
          min-height: 168px;
          margin-top: 24px;
          img {
            max-height: 168px;
            max-width: 168px;
          }
        }
        &__score--score {
          font-weight: 700;
          font-size: 72px;
          text-align: center;
          line-height: 20px;
          font-size: 72px;
          color: $greyish;
          margin-left: 54px;
          margin-right: 54px;
          strong {
            color: $section-heading-color;
          }
        }
      }
    }
  }
}
