.social-module {
  @include module-margins();
  &__heading {
    @include section-heading;
  }
  &__images {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    > .tile {
      @include image-background();
      height: 51vw;
      margin-bottom: -1px;
    }
    .tile__overlay {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
  &__links {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 85%;
    svg {
      height: 40px;
      width: 40px;
      transition: $transition;
      &:hover {
        fill: $highlight;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .social-module {
    &__container {
      @include make-container();
      @include make-container-max-widths()
    }
    &__images {
      > .tile {
        height: 265px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .social-module {
    &__images {
      > .tile {
        height: 355px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .social-module {
    &__images {
      > .tile {
        height: 235px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          &::before {
            background-color: rgba($highlight, 0.9);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .social-module {
    &__images {
      > .tile {
        height: 288px;
      }
    }
  }
}
