.loader,
.loader:after {
  border-radius: 20px;
  width: 28px;
  height: 28px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}
.loader {
  border-top: 2px solid rgba($tertiary-color-hover, 0.8);
  border-right: 2px solid rgba($tertiary-color-hover, 0.8);
  border-bottom: 2px solid rgba($tertiary-color-hover, 0.8);
  border-left: 2px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.8s infinite linear;
  animation: load8 0.8s infinite linear;
  &.postioned {
	 align-self: center;
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Vertical align in button hack 
a .loader {
  margin-top: -4px;
}
