.match-report {
  &__image-wrap {
    margin-bottom: 16px;
  }
  &__header {
    margin-bottom: 16px;
  }
  &__social {
    text-align: right;
    margin-bottom: 16px;
  }
  &__date {
    color: #000C14;
    font-weight: 700;
    margin-bottom: 32px;
  }
  .game-card {
    margin-bottom: 16px;
  }
  .stats-card {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@include media-breakpoint-between(xs, md) {
  .match-report--desktop {
    display: none;
  }
}


@include media-breakpoint-between(lg, xl) {
  .match-report--mobile {
    display: none;
  }
}


@include media-breakpoint-up(md) {
  .match-report {
    &__image-wrap {
      margin-bottom: 32px;
    }
    .game-card {
      margin-bottom: 24px;
    }
    .stats-card {
      margin-top: 64px;
      margin-bottom: 64px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .match-report {
    &__image-wrap {
      margin-bottom: 50px;
    }
    .game-card {
      margin-bottom: 32px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .match-report {
    &__image-wrap {
      margin-bottom: 70px;
    }
    .game-card {
      margin-bottom: 40px;
    }
  }
}
