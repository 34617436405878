.ui-index {
  .container {
    position: relative;
    padding-top: 80px;
    > .row img {
      position: absolute;
      right: 0;
      top: 25px;
    }
  }
  &__heading {
    padding-bottom: 25px;
    font-size: 14px;
    font-weight: bold;
    color: $secondary-color;
    border-bottom: 1px solid #dee2e6;
    margin-top: 98px;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  &__item {
    a {
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dee2e6;
      img {
        height: 20px;
      }
      &:hover {
        background-color: rgba($secondary-color, 0.05);
      }
    }
  }
}
