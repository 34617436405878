// Colors

$primary-color: #002b5c;
$secondary-color: #00a88f;
$tertiary-color: #e50e63;
$tertiary-color-hover: #ff3a88;
$dark-navy-blue: #000c14;
$off-white: #f4f4f4;
$greyish: #b3b3b3;

$primary: #002b5c;
$secondary: #00a88f;

$a-tag-start-color: #000;
$tooltip-text-color: #ffffff;
$section-heading-color: $primary-color;

$footer-text-color: #fff;
$footer-background-color: #001b3a;
$mobile-menu-background-color: $primary-color;
$mobile-menu-text-color: #fff;
$nav-background-color: #fff;
$nav-text-color: #191919;
$nav-is-transparent: true;
$nav-transparent-text-color: #FFF;

$menu-text: #000;
$body-text-color: #555555;
$body-background-color: white;

$highlight: $secondary-color;
$placeholder: #717067;
$border-color: #edeae5;


// Slider
$slider-title-color: #fff;
$slider-title-color-mobile: #fff;
$slider-dots-color: #fff;
$slider-bg-color: $tertiary-color;


// Hovers

$box-shadow-initial: 0 0 0px 0px rgba(0,0,0,0);
$box-shadow-hover: 0 5px 24px 8px rgba(0,0,0,0.15);
$section-heading-more-hover: #000;

// Easing
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);

// Transitions
$card-transition: 0.2s $easeOutQuad;
$transition-speed: 0.2s $easeOutQuad;
$transition: all $transition-speed;


// Fonts

@font-face {
  font-family: GrotzecCond;
  font-weight: 900;
  font-style: italic;
  src: url("../../fonts/GrotzecCond-BlackItalic.otf") format("opentype");
}

$heading-font-family: 'GrotzecCond', Helvetica, Arial, sans-serif !default;
$base-font-family: 'Poppins', Helvetica, Arial, sans-serif !default;;

$base-line-height: 1.43;
$base-font-weight: 500;
$base-font-size: 1.4rem;

$h1-color: #fff;
$h1-font-style: italic;
$h1-font-family-mobile: $heading-font-family;
$h1-font-weight-mobile: 900;
$h1-font-size-mobile: 5.0rem;
$h1-line-height-mobile: 1;
$h1-text-transform-mobile: uppercase;
$h1-font-family-desktop: $heading-font-family;
$h1-font-weight-desktop: 900;
$h1-font-size-desktop: 9rem;
$h1-line-height-desktop: 0.91;
$h1-text-transform-desktop: uppercase;
$h1-letter-spacing-desktop: 0;
$h1-letter-spacing-mobile: 0;

$h2-color: inherit;
$h2-font-family-mobile: $base-font-family;
$h2-font-weight-mobile: 700;
$h2-font-size-mobile: 2.6rem;
$h2-line-height-mobile: 1.31;
$h2-font-style-mobile: none;
$h2-text-transform-mobile: none;
$h2-font-family-desktop: $heading-font-family;
$h2-font-weight-desktop: 900;
$h2-font-size-desktop: 4.8rem;
$h2-line-height-desktop: 0.92;
$h2-font-style-desktop: italic;
$h2-text-transform-desktop: uppercase;
$h2-letter-spacing-desktop: 0;
$h2-letter-spacing-mobile: 0;

$h3-color: inherit;
$h3-font-weight: 700;
$h3-font-style: none;
$h3-font-family: $base-font-family;
$h3-font-size: 2.6rem;
$h3-line-height: 1.31;

$h4-color: inherit;
$h4-font-weight: 700;
$h4-font-style: none;
$h4-font-family: $base-font-family;
$h4-font-size: 1.8rem;
$h4-line-height: 1.44;

$paragraph-color: $body-text-color;
$paragraph-font-weight-mobile: 400;
$paragraph-font-size-mobile: 1.6rem;
$paragraph-line-height-mobile: 1.63;
$paragraph-font-size-desktop: 1.8rem;
$paragraph-line-height-desktop: 1.56;

$paragraph-heading-color: $secondary-color;
$paragraph-heading-font-weight-mobile: 600;
$paragraph-heading-font-size-mobile: 2.0rem;
$paragraph-heading-line-height-mobile: 1.5;
$paragraph-heading-font-size-desktop: 2.4rem;
$paragraph-heading-line-height-desktop: 1.33;

$micro-heading-color: $dark-navy-blue;
$micro-heading-font-weight: 700;
$micro-heading-font-size: 1.0rem;
$micro-heading-line-height: 1.5;
$micro-heading-letter-spacing: 0.4px;
$micro-heading-text-transform: uppercase;

$mega-heading-color: #fff;
$mega-heading-font-style: italic;
$mega-heading-font-family: $heading-font-family;
$mega-heading-font-weight: 900;
$mega-heading-font-size: 10.1rem;
$mega-heading-line-height: 0.9;
$mega-heading-text-transform: uppercase;

$player-profile-heading: $h2-font-size-desktop;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1240px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1180px
) !default;
