aside.call-to-action {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 99;
	width: 100%;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;

		li {
			margin-bottom: 0;
			display: flex;
			width: 50%;

			&:first-of-type {
				border-right: 1px solid lighten($secondary-color, 5%);
			}
		}
		a {
			display: block;
			background-color: $secondary-color;
			color: white;
			font-size: 16px;
			font-weight: 600;
			text-align: center;
			padding: 16px 0 16px 0;
			width: 100%;
		}
	}

	@include media-breakpoint-up(md) {
		left: auto;
		right: 0;
		top: 340px;
		width: auto;
		max-width: 200px;

		ul {
			display: block;

			li {
				margin-bottom: 2px;
				width: auto;
				display: block;

				a {
					display: flex;
					padding: 16px 40px 16px 20px;
					text-align: left;
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					box-shadow: 2px 2px 4px rgba(0,0,0, 0.3);
					&:hover {
						background-color: darken( $secondary-color, 2% );
					}
					width: auto;
				}
			}
		}
	}
}

.packages {
	.call-to-action {
		@include module-margins();
		&__container {
			@include make-container-max-widths();
			@include make-container();
		}
		&__background {
			background-color: $off-white;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 32px 28px;
			flex-direction: column;
			border-radius: 6px;

		}
		&__heading {
			margin-bottom: 0;
			color: $body-text-color;
			text-align: center;
			@include fluid-property('font-size', 20, 18);
			line-height: 20px;
		}
		.button {
			margin-top: 23px;
		}
	}

	@include media-breakpoint-up(md) {
		.call-to-action {
			&__background {
				flex-direction: row;
			}
			.button {
				margin-top: 0;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.call-to-action {
			&__background {
				padding-left: 48px;
				padding-top: 32px;
				padding-bottom: 32px;

			}
		}
	}

}
