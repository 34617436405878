.match-wrap-up {
  &__info {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    &-item {
      display: flex;
      &:first-of-type {
        margin-right: 32px;
      }
    }
    &-heading {
      font-weight: 600;
      margin-right: 8px;
    }
    &-text {
      font-weight: 500;
    }
  }
  &__heading {
    margin-bottom: 35px;
  }
}

.quarters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.quarter {
  background-color: $off-white;
  flex: 0 0 100%;
  height: 106px;
  margin-top: 4px;
  padding: 22px 16px 19px 18px;
  > div:nth-of-type(2) {
    margin-bottom: 2px;
    .quarter__score {
      font-weight: 700;
      color: $section-heading-color;
      opacity: 1;
    }
    .stat-bar__foreground {
      width: 90%;
    }
  }
  > div:nth-of-type(3) {
    .stat-bar__foreground {
      width: 70%;
    }
  }
  &__bonus {
    position: absolute;
    height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    bottom: 17px;
    right: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  &__header {
    font-weight: 600;
    margin-bottom: 3px;
    line-height: 24px;
  }
  &__home, &__away {
    display: flex;
    align-items: center;
    position: relative;
  }
  &__home {
    .stat-bar__foreground {
      background-color: $tertiary-color;
    }
    .quarter__bonus {
      background-color: $tertiary-color;
    }
  }
  &__away {
    .stat-bar__foreground {
      background-color: #00A88F;
    }
    .quarter__bonus {
      background-color: #00A88F;
    }
  }
  &__score {
    flex: 0 0 30px;
    text-align: right;
    opacity: 0.58;
    font-size: 12px;
    color: #B3B3B3;
    letter-spacing: 0.33px;
    font-weight: 400;
  }
}

.stat-bar {
  flex: 1;
  height: 4px;
  position: relative;
  &__background {
    width: 100%;
    height: 100%;
    background-color: #E0E0E0;
  }
  &__foreground {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@include media-breakpoint-up(sm) {
  .quarter {
    flex: 0 0 calc(50% - 2px);
  }
}

@include media-breakpoint-up(lg) {
  .quarter {
    flex: 0 0 calc(25% - 3px);
  }
  .match-wrap-up {
    &__heading {
      margin-bottom: 48px;
    }
  }
}
