.search {
  position: fixed;
  top: 85px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-fixed;
  background-color: #fff;
  transition: $transition;
  padding-top: 64px;
  display: none;
  @include image-background();
  input {
    font-size: 20px;
    color: $body-text-color;
    font-weight: 600;
    border: 0;
    display: block;
    width: 89%;
    position: relative;
    margin: 0 auto 6px;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
  .container {
    text-align: center;
  }
  hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: $body-text-color;
    margin-bottom: 32px;
  }
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #BCBCBC;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #BCBCBC;
    opacity: 1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #BCBCBC;
    opacity: 1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #BCBCBC;
  }
  :placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
    color: #BCBCBC;
  }
}

@include media-breakpoint-up(lg) {
  .search {
    position: absolute;
    height: 112px;
    padding: 32px;
    width: 458px;
    box-shadow: 2px 2px 26px 0 rgba(0,0,0,0.20);
    border-radius: 8px;
    left: -384px;
    top: 43px;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    &::before {
      content: "";
      position: absolute;
      top: -8px;
      right: 22px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
    }
    form {
      display: flex;
      padding-bottom: 7px;
      border-bottom: 1px solid $body-text-color;
      align-items: flex-end;
      input {
        margin-bottom: 0;
        height: 28px;
        text-align: left;
      }
      hr {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .search {
    &::before {
      right: 14px;
    }
  }
}
