.mobile-side-nav {
  width: 100%;
  height: 52px;
  background-color: $secondary;
  margin-top: -40px;
  margin-bottom: 40px;
  position: relative;
  &::before {
    content: '';
    height: 9px;
    width: 12px;
    background-image: url('/static/media/svg/arrow-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
    bottom: 16px;
  }
  &::after {
    content: '';
    height: 9px;
    width: 12px;
    background-image: url('/static/media/svg/arrow-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    position: absolute;
    right: 15px;
    top: 16px;
  }
  &__menu {
    @extend .form-control;
    height: 52px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    &:focus {
      background: transparent!important;
      border: none;
      box-shadow: none;
      color: white;
    }
  }
}

@include media-breakpoint-up(md) {
  .mobile-side-nav {
    display: none;
  }
}
